/* eslint-disable react/display-name */
import { Button, ButtonBlue, Flex } from "components/common";
import { formatNumber } from "components/pachinko/Prizes";
import { TheIcon } from "components/TheComponents";
import Image from "next/image";
import React, { forwardRef, HTMLAttributes, CSSProperties } from "react";
import { css } from "styled-components";
import { ItemProps } from "../types";
import { Description, StatMessage, SubHeader, Card, ExtendedSubHeader } from "./ItemsTable";

export type ItemСomponentProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  withOpacity?: boolean;
  isDragging?: boolean;
  onSelectItem?: (item: ItemProps) => void;
  onOpenDelete?: () => void;
  setSelectedItem?: React.Dispatch<any>;
  isAdminPage?: boolean;
  disableDND?: boolean;
  item: ItemProps;
};

const Item = forwardRef<HTMLDivElement, ItemСomponentProps>(
  (
    {
      id,
      withOpacity,
      isDragging,
      style,
      item,
      onSelectItem,
      onOpenDelete,
      setSelectedItem,
      isAdminPage,
      disableDND,
      ...props
    },
    ref
  ) => {
    const inlineStyles: CSSProperties = {
      opacity: withOpacity ? "0.5" : "1",
      transformOrigin: "50% 50%",
      cursor: !disableDND ? (isDragging ? "grabbing" : "grab") : "default",
      display: "flex",
      color: "black",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: isDragging
        ? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
        : "none",
      transform: isDragging ? "scale(1.05)" : "none",
      ...style,
    };

    const lessThanSix = item.quantity < 6;

    return (
      <Card ref={ref} style={inlineStyles} {...props}>
        <Flex width="100%" align="center" justify="flex-end" style={{ marginRight: 25 }}>
          {lessThanSix ? (
            <Image
              src="/images/PointShopComponent/warning.svg"
              height={16}
              width={16}
              alt="image"
            />
          ) : null}
          <Flex>
            <ExtendedSubHeader>
              <span style={{ color: lessThanSix ? "rgba(238, 185, 0, 1)" : "white", fontSize: 18 }}>
                {item.quantity}
              </span>
              /{item.stockQuantity}
            </ExtendedSubHeader>
            <ExtendedSubHeader style={{ whiteSpace: "nowrap" }}>LEFT</ExtendedSubHeader>
          </Flex>
        </Flex>
        <Flex justify="center" align="center" column>
          <Image src={item.imageUrl} height={150} width={150} alt="image" />
          <SubHeader
            style={{
              height: 42,
              paddingBottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {item.title}
          </SubHeader>
          <Description showTooltip={item.description.length > 10 || false} title={item.description}>
            {item.description.length > 30
              ? item.description.slice(0, 30) + "..."
              : item.description}
          </Description>
          <Flex margin="5px 0px 10px">
            <Image src="/images/PointShopComponent/coin.png" height={30} width={30} alt="image" />
            <StatMessage>{formatNumber(item.price)}</StatMessage>
          </Flex>
          {isAdminPage ? (
            <Flex>
              <Button
                styles={css`
                  width: 120%;
                  cursor: pointer;
                  white-space: nowrap;
                  height: 44px;
                  margin-right: 10px;
                `}
                preset="blue"
                isDark
                onClick={() => {
                  onSelectItem?.(item);
                }}
              >
                <ButtonBlue isDark>Edit item</ButtonBlue>
              </Button>
              <Button
                styles={css`
                  cursor: pointer;
                  height: 44px;
                  padding: 10px 15px;
                `}
                preset="blue"
                isDark
                onClick={() => {
                  onOpenDelete?.()
                  setSelectedItem?.(item);
                }}
              >
                <ButtonBlue isDark>
                  {" "}
                  <TheIcon icon="action:remove" size={16} />
                </ButtonBlue>
              </Button>
            </Flex>
          ) : (
            <Button
              styles={css`
                width: 180px;
              `}
              preset="blue"
              isDark={item.quantity === 0 || false}
              onClick={() => {
                if (item.quantity > 0) {
                  onSelectItem?.(item);
                }
              }}
            >
              <ButtonBlue isDark={item.quantity === 0 || false} transparent>
                {item.quantity === 0 ? "SOLD OUT" : "BUY"}
              </ButtonBlue>
            </Button>
          )}
        </Flex>
      </Card>
    );
  }
);

export default Item;
