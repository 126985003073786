import React, { Dispatch, SetStateAction } from "react";

import styled from "styled-components";
import { Button, ButtonBlue, Flex } from "components/common";
import Image from "next/image";
import { css } from "styled-components";
import { IAppContext, withApp } from "contexts";
import MFAModal from "components/ScreenComponent/MFAModals/MFAModal";

interface MainProps {
  disableConnectionsEdit: boolean;
  kickUsername?: string | null;
  getConnections?: () => void;
  updateInput: ({ field, value }: { field: string; value: string | number }) => Promise<void>;
  unlinkPlatform: ({ platform, otp }: { platform: string; otp: string }) => Promise<void>;
  isMfaEnabled: boolean;
  isUnlinkLoading: boolean;
  isOwnProfile: boolean;
  isEditable: boolean;
  platformToUnlink: string | null;
  setPlatformToUnlink: React.Dispatch<React.SetStateAction<string | null>>;
  setShowKickGuideModal: Dispatch<SetStateAction<boolean>>;
  showVerifyModal: boolean;
  setShowVerifyModal: Dispatch<SetStateAction<boolean>>;
  isPlatformCanConnect: (platform: string) => Promise<boolean>;
  
}

const KickButton: React.FC<MainProps & IAppContext> = ({
  kickUsername,
  getConnections,
  unlinkPlatform,
  isUnlinkLoading,
  setShowQrModal,
  setShowKickGuideModal,
  platformToUnlink,
  setPlatformToUnlink,
  isMfaEnabled,
  isEditable,
  isOwnProfile,
  showVerifyModal,
  setShowVerifyModal,
  isPlatformCanConnect,
}): React.ReactElement => {
  const platform = "Kick";
  return (
    <Flex column width="100%">
      <Flex align="center" justify="center" width="100%" column>
        <Button
          styles={css`
            height: 50px;
            width: 100%;
            padding: 10px 22px;
            cursor: ${!isOwnProfile ? "default !important" : "pointer"};
            pointer-events: ${kickUsername ? "none !important" : "auto"};
          `}
          height={50}
          isDark
          onClick={
            async () => {
              const isCanConnect = await isPlatformCanConnect('kick');
              if (isCanConnect) {
                if (isOwnProfile) {
                  !kickUsername && setShowKickGuideModal(true)   
                }
              }
            }
          }
        >
          <ButtonBlue isDark>
            <Image src="/images/TheButton/kick.png" height={20} width={20} alt="image" />
            {kickUsername || (!isOwnProfile && "Not Linked") || "Link Kick"}
            {/* To do */}
            {kickUsername ? (
              <Image
                src="/images/TheIcon/checkbox-checked-light.svg"
                height={15}
                width={15}
                alt="image"
              />
            ) : null}
          </ButtonBlue>
        </Button>
        <Flex width="100%" justify="center" align="center">
          {!kickUsername ? (
            <UnlinkButton onClick={getConnections}>Recheck</UnlinkButton>
          ) : isEditable ? (
            <UnlinkButton
              onClick={(e) => {
                e.preventDefault();
                setPlatformToUnlink(platform);
                if (isMfaEnabled) {
                  setShowVerifyModal(true);
                } else {
                  setShowQrModal(true);
                }
              }}
            >
              Unlink Kick
            </UnlinkButton>
          ) : null}
        </Flex>
      </Flex>

      {isMfaEnabled && showVerifyModal && platformToUnlink === platform && (
        <MFAModal
          isLoading={isUnlinkLoading}
          onVerify={async (otpObject) => {
            unlinkPlatform({ platform, otp: otpObject.otp });
          }}
          onClose={() => {
            setShowVerifyModal(false);
            setPlatformToUnlink(null);
          }}
        />
      )}
    </Flex>
  );
};

export default withApp(KickButton);

const UnlinkButton = styled.div`
  color: rgba(210, 234, 255, 0.5);
  cursor: pointer;
  margin: 10px;

  &:hover {
    color: rgba(210, 234, 255, 0.75) !important;
  }
`;
