import React from "react";

import styled from "styled-components";
import { Button, ButtonBlue, DualRingInner, DualRingLoader, Flex } from "components/common";
import Image from "next/image";
import { css } from "styled-components";
import { IAppContext, withApp } from "contexts";
import { SubHeader } from "../ProfileLayout";
import { triggerNotification } from "components/TheComponents/Notification";
import { ExceptionHandlingType } from "types/apiServiceTypes";

interface MainProps {
  userDiscordUsername: string | null;
  refetchUser: () => Promise<void>;
  isOwnProfile: boolean;
  onDiscordRedirect?: () => void;
  isDiscordLinked: boolean;
  isEditable: boolean;
  hasVerifiedRoleOnDiscordGuild: boolean;
  unlinkPlatform: ({ platform, otp }: { platform: string; otp: string }) => boolean;
  isMfaEnabled: boolean;
  userId: string;
}

const DiscordButton: React.FC<MainProps & IAppContext> = ({
  refetchUser,
  userDiscordUsername,
  hasVerifiedRoleOnDiscordGuild,
  isDiscordLinked,
  onDiscordRedirect,
  isEditable,
  accessFetch,
  userId,
  isOwnProfile,
}): React.ReactElement => {
  const [isVerifyLoading, setIsVerifyLoading] = React.useState<boolean>(false);
  const unlinkDiscord = React.useCallback(async () => {
    try {
      const unlinkResponse = await accessFetch(
        `/user/${userId}/unlink-discord`,
        {
          method: "PATCH",
        },
        ExceptionHandlingType.AUTOMATIC
      );
      const json = await unlinkResponse?.json();

      if (unlinkResponse && (unlinkResponse.status === 200 || unlinkResponse.status === 201)) {
        triggerNotification({ text: "Discord unlinked", type: "success" });
        refetchUser();
      } else {
        triggerNotification({ text: json?.message, type: "error" });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [accessFetch, refetchUser, userId]);

  const verifyDiscord = React.useCallback(async () => {
    try {
      setIsVerifyLoading(true);
      const verifyResponse = await accessFetch(
        `/user/${userId}/verify-discord`,
        {},
        ExceptionHandlingType.AUTOMATIC
      );
      const data = await verifyResponse?.json();

      if (
        verifyResponse &&
        (verifyResponse.status === 200 || verifyResponse.status === 201) &&
        data?.result !== hasVerifiedRoleOnDiscordGuild
      ) {
        refetchUser();
      }

      triggerNotification({
        text: `Discord ${data?.result ? "" : " not "} verified`,
        type: data?.result ? "success" : "error",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsVerifyLoading(false);
    }
  }, [accessFetch, refetchUser, userId, hasVerifiedRoleOnDiscordGuild]);

  return (
    <Flex style={{ marginRight: 20 }} column width="100%">
      <Flex align="center" justify="center" width="100%" column>
        <Button
          styles={css`
            height: 50px;
            width: 100%;
            padding: 10px 22px;
            cursor: ${!isOwnProfile ? "default !important" : "pointer !important"};
            pointer-events: ${userDiscordUsername ? "none !important" : "auto"};
          `}
          height={50}
          isDark
          onClick={!isOwnProfile ? () => {} : onDiscordRedirect}
        >
          <ButtonBlue isDark>
            <Image src="/images/icons/discord-purple.png" height={15} width={19} alt="image" />
            {userDiscordUsername
              ? userDiscordUsername
              : (!isOwnProfile && "Not Linked") || "Link Discord"}
            {isDiscordLinked ? (
              <Image
                src={
                  hasVerifiedRoleOnDiscordGuild
                    ? "/images/TheIcon/checkbox-checked-light.svg"
                    : "/images/TheIcon/warning.png"
                }
                height={hasVerifiedRoleOnDiscordGuild ? 15 : 25}
                width={hasVerifiedRoleOnDiscordGuild ? 15 : 25}
                alt="image"
              />
            ) : null}
          </ButtonBlue>
        </Button>
        {isDiscordLinked ? (
          <Flex justify={"space-between"} width="100%">
            {isEditable && <UnlinkButton onClick={unlinkDiscord}>Unlink</UnlinkButton> } 
            <UnlinkButton onClick={verifyDiscord}>
              {isVerifyLoading ? (
                <Flex justify="center" width="100%">
                  <DualRingLoader smallRing>
                    <DualRingInner smallRing />
                  </DualRingLoader>
                </Flex>
              ) : (
                "Recheck"
              )}
            </UnlinkButton>
          </Flex>
        ) : null}
        {isDiscordLinked ? (
          <Flex align="center" justify="center">
            <SubHeader style={{ fontSize: 16, marginRight: 3 }}>
              Server joined and verified
            </SubHeader>
            <Image
              src={
                hasVerifiedRoleOnDiscordGuild
                  ? "/images/icons/check.png"
                  : "/images/icons/x-mark.png"
              }
              width={20}
              height={18}
              alt="mic"
            />
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default withApp(DiscordButton);

const UnlinkButton = styled.div`
  color: rgba(210, 234, 255, 0.5);
  cursor: pointer;
  margin: 10px auto;
  width: 63px;

  &:hover {
    color: rgba(210, 234, 255, 0.75) !important;
  }
`;
