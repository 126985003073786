import { TheIcon } from "components/TheComponents";
import { Flex } from "components/common/$styles";
import { TTheIconIcon } from "interfaces";
import React, { Dispatch, SetStateAction, useEffect, useId, useRef } from "react";
import styled, { FlattenSimpleInterpolation, css } from "styled-components";
type PaginationAction = "left" | "right";
export interface ITabsProps {
  tabsList: ITab[];
  headerStyles?: ITabHeaderStyles;
  selectedTabActions: [ITabDefault, Dispatch<SetStateAction<ITabDefault>>];
}

export interface ITabDefault {
  key: string;
  tabTitle: string;
}

export interface ITab extends ITabDefault {
  styles?: string;
  tabContent?: React.ReactElement;
}

export interface ITabHeaderStyles {
  active?: FlattenSimpleInterpolation;
  inactive?: FlattenSimpleInterpolation;
}

export default function Tabs({ tabsList, headerStyles, selectedTabActions }: ITabsProps) {
  const tabsWrapper = useRef<HTMLDivElement>(null);
  const tabMinWidth = 170;
  const [tabsPage, setTabsPage] = React.useState<number>(0);
  const [take, setTake] = React.useState<number>(0);
  const [skip, setSkip] = React.useState<number>(0);
  const [selectedTab, setSelectedTab] = selectedTabActions;
  const [tabsPerPage, setTabsPerPage] = React.useState<number>(0);
  const [isLastPage, setIsLastPage] = React.useState<boolean>(false);
  const [paginationAction, setPaginationAction] = React.useState<PaginationAction>();

  useEffect(() => {
    function updateTabsPerPage() {
      const tabsByContainerWidth = Math.floor(
        (tabsWrapper.current?.offsetWidth ?? 0) / tabMinWidth
      );
      setPaginationAction(undefined);
      setTabsPerPage(tabsByContainerWidth);
      setTabsPage(0);
    }
    window.addEventListener("resize", updateTabsPerPage);
    return () => window.removeEventListener("resize", updateTabsPerPage);
  }, []);

  useEffect(() => {
    const tabsByContainerWidth = Math.floor((tabsWrapper.current?.offsetWidth ?? 0) / tabMinWidth);

    const lastToTake = tabsPage * tabsByContainerWidth + tabsByContainerWidth;
    const tabsToTake = tabsList.length < lastToTake ? tabsList.length : lastToTake;
    setTake(tabsToTake);

    const tabsToSkip = tabsPage * tabsByContainerWidth;
    setSkip(tabsToSkip);
    setIsLastPage(lastToTake >= tabsList.length);
  }, [tabsPage, tabsPerPage, tabsList, tabsWrapper]);

  return (
    <TabsWrapper ref={tabsWrapper}>
      <TabsHeadersWrapper>
        <PaginationAction iconType={"left"} />
        <TabsList>
          {tabsList.slice(skip, take).map((tab) => (
            <TabHeader
              tabsPerPage={take - skip}
              animationDirection={paginationAction}
              key={tab.key}
              onClick={(e) => onTabClick(e, tab)}
            >
              <TabHeaderContent headerStyles={headerStyles} isActive={selectedTab.key === tab.key}>
                {tab.tabTitle}
              </TabHeaderContent>
            </TabHeader>
          ))}
        </TabsList>
        <PaginationAction iconType={"right"} />
      </TabsHeadersWrapper>
      <TabsContent>{tabsList.find((tab) => tab.key === selectedTab.key)?.tabContent}</TabsContent>
    </TabsWrapper>
  );

  function onTabClick(event: React.MouseEvent, tab: ITab) {
    setSelectedTab(tab);
  }

  function PaginationAction({ iconType }: { iconType: PaginationAction }) {
    const iconAction = iconType === "left" ? -1 : 1;
    const icon = `arrow:small:${iconType}:active`;
    const onClickCallback = () => {
      setTabsPage(tabsPage + iconAction);
      setPaginationAction(iconType);
    };
    if (iconType === "left" && tabsPage === 0) return <TabIconWrapper />;
    if (iconType === "right" && isLastPage) return <TabIconWrapper />;
    return (
      <TabIconWrapper>
        <TabIconContainer onClick={onClickCallback}>
          <TheIcon icon={icon as TTheIconIcon} />
        </TabIconContainer>
      </TabIconWrapper>
    );
  }
}

const TabHeader = styled.div<{ tabsPerPage: number; animationDirection?: PaginationAction }>`
  min-width: 130px;
  height: 100%;
  white-space: nowrap;
  flex: 1 0 0;

  ${({ animationDirection, tabsPerPage }) => {
    switch (animationDirection) {
      case "left":
        return css`
          animation: xPositionChange-left 0.5s;
          @keyframes xPositionChange-left {
            100% {
              transform: translateX(0%);
            }
            0% {
              transform: translateX(-${100 * tabsPerPage}%);
            }
          }
        `;
      case "right":
        return css`
          animation: xPositionChange-right 0.5s;
          @keyframes xPositionChange-right {
            100% {
              transform: translateX(0%);
            }
            0% {
              transform: translateX(${100 * tabsPerPage}%);
            }
          }
        `;
    }
  }}
`;

const defaultTabHeaderStyle = css`
  border-bottom-style: solid;
  border-width: 2px;
`;

const TabHeaderContent = styled.div<{
  isActive?: boolean;
  headerStyles?: ITabHeaderStyles;
}>`
  user-select: none;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: rgba(210, 234, 255, 0.75);
  height: 100%;
  font-weight: 600;
  padding: 10px 15px;
  text-transform: uppercase;

  border-bottom-color: transparent;
  ${defaultTabHeaderStyle}
  border-image-source: linear-gradient(265.52deg, rgb(71, 150, 242), rgb(71, 211, 242));
  ${({ isActive, headerStyles }) =>
    isActive
      ? css`
          ${headerStyles?.active}
          border-image-slice: 1;
        `
      : css`
          ${headerStyles?.inactive}
          border-image-slice: 0;
          &:hover {
            border-image-slice: 1;
          }
        `}
`;

const TabsHeadersWrapper = styled(Flex)`
  display: flex;
  margin-bottom: 15px;
  height: fit-content;
  width: 100%;
`;

const TabsList = styled(Flex)`
  display: flex;
  gap: 20px;
  width: calc(100% - 50px);
  height: 100%;
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TabsContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabIconWrapper = styled.div`
  height: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  z-index: 25;
  width: 30px;
  justify-content: center;
`;

const TabIconContainer = styled.div`
  cursor: pointer;
  height: 25px;
  width: 25px;
  & > div {
    background-size: 25px 25px;
    height: 25px;
    width: 25px;
  }
  &:hover {
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  }
  &:active {
    transform: scale(0.95);
  }
`;
