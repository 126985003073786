import styled from "styled-components";
import { Button as UIButton } from "components/common";

export const Button = styled(UIButton)`
  width: 100%;
  text-transform: uppercase;
  margin-top: 24px;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const HeaderBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #e9f4ff;
  margin: 0;
  text-transform: uppercase;
`;

export const SubTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: ${({ color }) => color || "#e9f4ffbf"};
  margin: 0;
  max-width: 80%;
`;

export const RateTitle = styled.h4`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #e9f4ff;
  text-transform: uppercase;
`;

export const RateBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const RateBanner = styled.div`
  position: relative;
  display: flex;
  background: #e9f4ff0d;
  width: 100%;
`;

export const Equal = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Golos Text;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #e9f4ffbf;
`;

export const RateItem = styled.div`
  flex: 1;
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 1px solid #e9f4ff1a;
  &:first-child {
    border-radius: 6px 0 0 6px;
  }
  &:last-child {
    border-radius: 0 6px 6px 0;
  }
`;

export const CoinTitle = styled.div`
  display: flex;
  color: #e9f4ff80;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-transform: uppercase;
  gap: 4px;
`;

export const CoinValue = styled.div`
  color: #e9f4ff;
`;

// old
export const ExchangeFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
`;

export const ExchangeInput = styled.input`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #e9f4ff;
  text-transform: uppercase;
`;

export const ModalBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const InputWrapper = styled.div<{ active?: boolean }>`
  display: flex;
  width: 100%;
  padding: 8px;
  align-items: center;
  border-radius: 4px;
  gap: 4px;
  ${({ active }) =>
    active
      ? `
    border: 1px solid #E9F4FF0D;
  `
      : `
      background: #E9F4FF0D;
    border: 1px solid #E9F4FF0D; 
    `}
`;

export const Input = styled.input`
  width: 100%;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #e9f4ff;

  &:focus {
    outline: none;
  }
`;

export const BalanceCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px;
  text-transform: uppercase;
  background: #e9f4ff0d;
  color: #e9f4ff80;
  border-radius: 4px;
`;

export const Balance = styled.div`
  color: #e9f4ff;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
