import React from "react";
import styled, { css } from "styled-components";
import { IStyles, $styles, Button, ButtonBlue } from "components";
import { DualRingInner, DualRingLoader, Flex } from "components/common";
import Image from "next/image";
import { Description, SubHeader } from "../ItemsTable/ItemsTable";
import { formatNumber } from "components/pachinko/Prizes";
import { triggerNotification } from "components/TheComponents/Notification";
import { useAppContext} from "contexts";
import SuccessfulPurchaseLayout from "./SuccessfulPurchaseModal";
import FailedPurchaseLayout from "./FailedPurchaseLayout";
import { ItemProps } from "../types";
import { useDisclosure } from "@chakra-ui/react";
import ChakraModal from "components/TheComponents/Modal";

const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 0px;
  gap: 8px;
  border: 1px solid rgba(218, 248, 255, 0.1);
  width: 100%;

  background: #181f2e;
  border-radius: 8px;
  background-image: url("/images/PointShopComponent/w-logo.png");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center 20%;
  background-clip: border-box;
`;

const SubHeaderExtended = styled.h1`
  text-align: left;
  margin: 0;
  color: #99abbd;
  padding: 5px;
  font-weight: 500;
  font-size: 14px;
  @media (max-width: 1280px) {
    font-size: 13px;
  }
  @media (max-width: 480px) {
    font-size: 11px;
  }
`;

interface PurchaseModalProps {
  onClose: () => void;
  isDiscordLinked?: boolean;
  selectedItem: ItemProps;
}

const PurchaseLayout: React.FC<PurchaseModalProps> = ({
  onClose,
  selectedItem,
}): React.ReactElement => {
  const { accessFetch, refetchProfile } = useAppContext();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const { isOpen: isSuccessOpen, onOpen: onOpenSuccess, onClose: onCloseSuccess } = useDisclosure();
  const { isOpen: isFailedOpen, onOpen: onOpenFailed, onClose: onCloseFailed } = useDisclosure();

  const buyItem = async (event: React.MouseEvent) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const response = await accessFetch("/point-shop/purchases", {
        method: "POST",
        body: JSON.stringify({
          pointsShopItemId: selectedItem.id,
        }),
      });
      const data = await response?.json();
      if (response && (response.status === 200 || response.status === 201)) {
        onOpenSuccess();
      } else {
        onOpenFailed();
        triggerNotification({ text: data?.message, type: "error" });
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
    }
  };

  const onSuccess = async () => {
    onCloseSuccess();
    onClose();

    refetchProfile();
  };
  return (
    <Flex column>
      <SubHeaderExtended>All redemptions will be manually reviewed.</SubHeaderExtended>
      <SubHeaderExtended>Prize will be awarded to active community members only.</SubHeaderExtended>
      <SubHeaderExtended style={{ marginBottom: 15 }}>
        Physical prizes can&apos;t be traded for cash.
      </SubHeaderExtended>
      <Card style={{ marginBottom: 20 }}>
        <Flex justify="center" align="center" column>
          <Image src={selectedItem.imageUrl} height={150} width={150} alt="image" />
          <SubHeader style={{ fontSize: 16, color: "#A3B6CA" }}>{selectedItem.title}</SubHeader>
          <Description
            showTooltip={selectedItem.description.length > 10 || false}
            title={selectedItem.description}
          >
            {selectedItem.description.length > 10
              ? selectedItem.description.slice(0, 10) + "..."
              : selectedItem.description}
          </Description>
        </Flex>
      </Card>
      <Flex width="100%" justify="space-between">
        <BoxItem
          styles={css`
            margin-top: 8px;
            @media (max-width: 600px) {
              width: 50%;
            }
          `}
        >
          <Button
            styles={css`
              margin-right: 10px;
              height: 50px;
              background: #181f2e;
              box-shadow: none;
            `}
            onClick={onClose}
            isDark
          >
            <ButtonBlue isDark>Back</ButtonBlue>
          </Button>
        </BoxItem>
        <BoxItem
          styles={css`
            margin-top: 8px;
            height: 50px;
            @media (max-width: 600px) {
              width: 50%;
            }
          `}
        >
          <Button
            isLoading={isLoading}
            styles={css`
              height: 50px;
            `}
            onClick={buyItem}
          >
            <ButtonBlue style={{ whiteSpace: "nowrap" }}>
              {isLoading ? (
                <DualRingLoader smallRing>
                  <DualRingInner smallRing />
                </DualRingLoader>
              ) : (
                <>
                  {"Buy"}
                  <Image
                    src="/images/PointShopComponent/coin.png"
                    height={30}
                    width={30}
                    alt="image"
                  />
                  {formatNumber(selectedItem.price)}
                </>
              )}
            </ButtonBlue>
          </Button>
        </BoxItem>
      </Flex>
      {/* Success case */}
      <ChakraModal
        isOpen={isSuccessOpen}
        onClose={onSuccess}
        content={<SuccessfulPurchaseLayout selectedItem={selectedItem} onClose={onSuccess} />}
      />

      {/* Fail case */}
      <ChakraModal
        isOpen={isFailedOpen}
        onClose={onCloseFailed}
        content={<FailedPurchaseLayout onClose={onCloseFailed} />}
      />
    </Flex>
  );
};

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;

  ${$styles}
`;

export default PurchaseLayout;
