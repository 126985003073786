import { useRouter } from "next/router";
import Link from "next/link";
import React, { ReactNode, useEffect } from "react";
import styled, { css } from "styled-components";
import { TheButton } from "components";
import { IAppContext, useAppContext, withApp } from "contexts";

import ChakraModal from "components/TheComponents/Modal";
import ProfileLayout from "components/public/ProfileLayout";
import { TwitchLoginLayout } from "components/public";
import { useDisclosure } from "@chakra-ui/react";
import LogoutLayout from "components/public/LogoutLayout";
import {
  AdaptiveLogo,
  DropNav,
  Logo,
  Nav,
  Profile,
  Header,
  Slide,
  Root,
  MenuToggler,
  DropDownBack,
  LogoutButton,
  MobileHeaderBlock,
  SignInButton,
} from "./styles";
import Crown from "./components/icons/Crown";
import Gift from "./components/icons/Gift";
import Others from "./components/icons/Others";
import Support from "./components/icons/Support";
import Contact from "./components/icons/Contact";
import Calendar from "./components/icons/Calendar";
import NavLink from "./components/NavLink";
import HeaderProfile from "./components/HeaderProfile";
import { filterNavItems } from "./utils/filterNavItems";
import StreamIndicator from "./components/StreamIndicator";
import Seven from "./components/icons/Seven";
import useCurrentUser, { useUserClientActions } from "hooks/user/useCurrentUser";
import Image from "next/image";
const menuItems: ITopMenuItem[] = [
  {
    name: "Admin",
    href: "/admin",
    type: "admin",
    isDesktopOnly: true,
  },
  {
    name: "All bonuses",
    href: "/all-bonuses",
    image: <Gift />,
  },
  {
    name: "Leaderboards",
    href: "/leaderboards",
    image: <Crown />,
  },
  {
    name: "All Rewards",
    image: <Gift />,
    items: [
      {
        name: "All Bonuses",
        href: "/all-bonuses",
      },
      {
        name: "Raffles",
        href: "/raffles",
      },
      {
        name: "Points Shop",
        href: "/points-shop",
      },
      {
        name: "Calendar",
        href: "/advent-calendar",
      },
      {
        name: "Leaderboards",
        href: "/leaderboards",
      },
      {
        name: "Pachinko",
        href: "/pachinko",
      },
    ],
  },
  {
    name: "Others",
    image: <Others />,
    items: [
      {
        name: "Hunt Tracker",
        href: "/hunt-tracker",
      },
    ],
  },
];

const addMenuItems: ITopMenuItem[] = [
  {
    name: "Support",
    href: "/faq",
    image: <Support />,
  },
  {
    name: "Contact",
    href: "/contact-us",
    image: <Contact />,
  },
];

const metaMenuItems: ITopMenuItem[] = [
  {
    name: "Privacy Policy",
    href: "/privacy-policy",
  },
  {
    name: "Terms of Service",
    href: "/terms-of-service",
  },
];

export interface ITopMenuItem {
  name: string;
  href?: string;
  type?: string;
  image?: ReactNode;
  isMobileOnly?: boolean;
  isDesktopOnly?: boolean;
  items?: ITopMenuItem[];
}

export interface IHeaderComponent {
  id?: string;
}

const HeaderComponentPure: React.FC<IHeaderComponent> = () => {
  const {
    profile,
    isAdmin,
    liveStreams,
    accessURL,
    loginModalProps,
    profileProps,
    isFeatureEnabled,
    featureFlags,
  } = useAppContext();

  const router = useRouter();

  const { logout, isLoadingUserInfo } = useUserClientActions();
  const { isLoginOpen, onOpenLogin, onCloseLogin } = loginModalProps;
  const { isProfileOpen, onOpenProfile, onCloseProfile } = profileProps;
  const { isOpen: isLogoutOpen, onOpen: onOpenLogout, onClose: onCloseLogout } = useDisclosure();
  const [items, setItems] = React.useState<ITopMenuItem[]>([]);
  const [itemKey, setItemKey] = React.useState<string>("");
  const [isOpenDropdown, setIsOpenDropdown] = React.useState<boolean>(false);
  const onToggle = () => {
    setIsOpenDropdown((prev) => !prev);
  };

  useEffect(() => {
    const newItems = filterNavItems(menuItems, isFeatureEnabled, profile, isAdmin);
    setItems(newItems);
  }, [profile, isFeatureEnabled, featureFlags, isAdmin]);

  const onActionClose = React.useMemo(() => {
    return (action: string) => {
      if (action === "login") {
        onCloseLogin();
      } else if (action === "logout") {
        onCloseLogout();
      } else if (action === "profile") {
        if (window) {
          window.history.replaceState(null, "", window.location.pathname);
        }
        onCloseProfile();
      }
    };
  }, [onCloseLogout, onCloseLogin, onCloseProfile]);

  const onLoginRedirect = React.useMemo(() => {
    return async () => {
      try {
        const url = accessURL(`/auth/twitch`) as URL;
        router.push(url.href);
      } catch (error) {
        console.log({ error });
      }
    };
  }, [accessURL, router]);

  const onLoginShow = React.useMemo(() => {
    return (event: React.MouseEvent) => {
      event.preventDefault();
      onOpenLogin();
    };
  }, [onOpenLogin]);

  const onLogoutRedirect = React.useMemo(() => {
    return async () => {
      try {
        router.push("/");
        logout();
      } catch (error) {}
    };
  }, [router, logout]);

  return (
    <>
      <Root>
        <Header fixed={isOpenDropdown}>
          <Logo>
            <Link href={"/"}>
              <AdaptiveLogo
                src={"/images/HeaderComponent/logo.svg"}
                width={136}
                height={36}
                alt="logo"
              />
            </Link>
            <StreamIndicator liveStreams={liveStreams} />
          </Logo>
          <Nav>
            {items.map((item, index) => {
              return (
                <NavLink
                  key={index}
                  item={item}
                  handleCheckboxChange={onToggle}
                  itemKey={itemKey}
                  setItemKey={setItemKey}
                />
              );
            })}
          </Nav>
          <Profile>
            {isLoadingUserInfo ? (
              <ProfileLoader>
                <Image
                  className="profile-loader"
                  alt="profile-image"
                  src="/images/Profile/watch.png"
                  width={40}
                  height={40}
                />
              </ProfileLoader>
            ) : !profile ? (
              <TheButton
                styles={css`
                  @media (max-width: 1480px) {
                    display: none;
                  }
                `}
                preset="dark-blue"
                onClick={onLoginShow}
              >
                Login
              </TheButton>
            ) : (
              <HeaderProfile
                onOpenProfile={onOpenProfile}
                onOpenLogout={onOpenLogout}
                profile={profile}
              />
            )}
            <MenuToggler>
              <input
                data-toggle
                onChange={onToggle}
                checked={isOpenDropdown}
                type="checkbox"
                id="top-menu-toggler"
              />
              <label data-button-container htmlFor="top-menu-toggler">
                <div data-button />
              </label>
            </MenuToggler>
          </Profile>
        </Header>
        <Slide in={isOpenDropdown} direction="top">
          <DropDownBack>
            {profile ? (
              <MobileHeaderBlock>
                <HeaderProfile
                  onOpenProfile={onOpenProfile}
                  onOpenLogout={onOpenLogout}
                  profile={profile}
                />
              </MobileHeaderBlock>
            ) : (
              <></>
            )}
            <MobileHeaderBlock>
              {profile ? (
                <LogoutButton onClick={onOpenLogout}>Log Out</LogoutButton>
              ) : (
                <SignInButton onClick={onLoginShow}>Sign in</SignInButton>
              )}
            </MobileHeaderBlock>
            <MobileHeaderBlock>
              <DropNav>
                {items.map((item, index) => {
                  return (
                    <NavLink
                      key={index}
                      item={item}
                      handleCheckboxChange={onToggle}
                      itemKey={itemKey}
                      setItemKey={setItemKey}
                    />
                  );
                })}
              </DropNav>
            </MobileHeaderBlock>
            <MobileHeaderBlock>
              <NavLink
                item={{
                  name: "Calendar",
                  href: "/advent-calendar",
                  image: <Calendar />,
                }}
                handleCheckboxChange={onToggle}
                itemKey={itemKey}
                setItemKey={setItemKey}
              />
            </MobileHeaderBlock>
            <MobileHeaderBlock>
              <DropNav>
                {addMenuItems.map((item, index) => {
                  return (
                    <NavLink
                      key={index}
                      item={item}
                      handleCheckboxChange={onToggle}
                      itemKey={itemKey}
                      setItemKey={setItemKey}
                    />
                  );
                })}
              </DropNav>
            </MobileHeaderBlock>
            <MobileHeaderBlock
              style={{
                borderBottom: "none",
              }}
            >
              <DropNav>
                {metaMenuItems.map((item, index) => {
                  return (
                    <NavLink
                      key={index}
                      item={item}
                      handleCheckboxChange={onToggle}
                      itemKey={itemKey}
                      setItemKey={setItemKey}
                    />
                  );
                })}
              </DropNav>
            </MobileHeaderBlock>
          </DropDownBack>
        </Slide>
      </Root>
      <ChakraModal
        isOpen={isLoginOpen}
        onClose={() => onActionClose("login")}
        size="xl"
        content={
          <TwitchLoginLayout onClose={() => onActionClose("login")} onLogin={onLoginRedirect} />
        }
      />
      <ChakraModal
        isOpen={isProfileOpen && !!profile}
        onClose={() => onActionClose("profile")}
        styles={css`
          height: 650px;
          overflow-y: scroll;
        `}
        size="2xl"
        content={
          <ProfileLayout
            userId={profile?.id as string}
            isMobile={false}
            // onClose={() => onActionClose("profile")}
          />
        }
      />
      <ChakraModal
        size="md"
        isOpen={isLogoutOpen}
        onClose={() => onActionClose("logout")}
        content={
          <LogoutLayout onClose={() => onActionClose("logout")} onLogout={onLogoutRedirect} />
        }
      />
    </>
  );
};

const ProfileLoader = styled.div`
  display: flex;
  font-family: "Roboto";
  gap: 5px;
  align-items: center;
  justify-content: center;
  .profile-loader {
    animation: tilt-n-move-shaking 0.7s linear infinite;
    animation-fill-mode: forwards;
  }

  @keyframes tilt-n-move-shaking {
    0% {
      transform: translate(0, 0) rotate(0deg);
    }
    25% {
      transform: translate(5px, 5px) rotate(5deg);
    }
    50% {
      transform: translate(0, 0) rotate(0eg);
    }
    75% {
      transform: translate(-5px, 5px) rotate(-5deg);
    }
    100% {
      transform: translate(0, 0) rotate(0deg);
    }
  }
`;
export const HeaderComponent = withApp(HeaderComponentPure);
