import styled from "styled-components";
import Image from "next/image";
import { $styles, Button as UIButton, IStyles } from "components";
import { PropsExtended } from "components/pachinko/types";
import { TRANSACTION_STATUS } from "helpers/constants";

export const Profile = styled(Image)`
  border-radius: 50%;
  ${$styles}
`;

export const Block = styled.div<IStyles>`
  border-top: 2px solid #1b2035;
  padding: 24px 0;
  ${$styles}
`;

export const MainBlock = styled(Block)`
  ${$styles}
`;

export const TotalBlock = styled.div<IStyles>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${$styles}
`;

export const TotalInfo = styled.div<IStyles>`
  display: flex;
  flex-direction: column;
  gap: 14px;
  ${$styles}
`;

export const TotalInfoItem = styled.div<IStyles>`
  display: flex;
  flex-direction: row;
  gap: 36px;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #d2eaffbf;

  ${$styles}
`;

export const PointBalance = styled.div<IStyles>`
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #24274680;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #d2eaff80;

  ${$styles}
`;

export const Status = styled.div<IStyles & { status: TRANSACTION_STATUS }>`
  color: ${({ status }) => {
      switch (true) {
        case status === TRANSACTION_STATUS.APPROVED:
          return "#448237";
        case status === TRANSACTION_STATUS.REJECTED:
          return "#9C392F";
        case status === TRANSACTION_STATUS.PENDING:
          return "#3B8BE9";
        default:
          "white";
      }
    }}
    ${$styles};
`;

export const StatusWrapper = styled.div<IStyles>`
  border-radius: 8px;
  padding: 8px 40px;
  background: #24274680;
  ${$styles};
`;

export const WonBlock = styled(Block)`
  display: flex;
  gap: 12px;
  ${$styles}
`;

export const WonItem = styled.div<IStyles>`
  ${$styles}
`;

export const LootBoxBlock = styled.div<IStyles>`
  display: flex;
  border-radius: 8px;
  padding: 8px 40px;
  background: #24274680;
  ${$styles}
`;

export const LootBoxImage = styled(Image)`
  ${$styles}
`;

export const LootBoxTitle = styled.div<IStyles>`
  font-family: Roboto;
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0.03em;
  color: #e0edfa;

  ${$styles}
`;

export const LootBoxDescription = styled.div<IStyles>`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #e2f1ffbf;

  ${$styles}
`;

export const CoinValue = styled.div<IStyles>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  color: #d2eaff;

  ${$styles}
`;

export const LootBoxSetting = styled.div<IStyles>`
  display: flex;
  gap: 12px;
  color: #e2f1ff66;

  ${$styles}
`;

export const LootBoxSettingValue = styled.div<IStyles>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  padding: 2px 8px;
  border: 1px solid #9b9b9b33;
  border-radius: 12px;
  align-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: italic;
  font-weight: 900;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: #e2f1ffbf;

  ${$styles}
`;

export const LootBoxInfo = styled.div<IStyles>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${$styles}
`;

export const LootBoxItemBlock = styled.div<IStyles>`
  display: flex;
  gap: 24px;
  ${$styles}
`;

export const UserBlock = styled.div<IStyles>`
  ${$styles}
`;
export const UserName = styled.span<IStyles>`
  font-family: Roboto;
  font-size: 16px;
  font-style: italic;
  font-weight: 900;
  line-height: 19px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #d2eaff;
  display: block;
  padding-bottom: 8px;

  ${$styles}
`;

export const UserID = styled(UserName)`
  color: #d2eaff40;

  ${$styles}
`;

export const MainUserBlock = styled.div<IStyles>`
  display: flex;
  flex-direction: row;
  gap: 16px;
  ${$styles}
`;

export const MainUserInfo = styled.div<IStyles>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${$styles}
`;

export const UserInfoItem = styled.div<IStyles>`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #d2eaff80;

  ${$styles}
`;

export const Button = styled(UIButton)`
  width: 82px;
  background: #0b101b;
  &: hover {
    opacity: 0.8;
  }

  &: active {
    opacity: 0.7;
  }
  ${$styles};
`;

export const Title = styled.div<PropsExtended>`
  color: rgba(210, 234, 255, 0.75);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  text-align: ${({ textAlign }) => textAlign || "center"};
  ${$styles}
`;

export const Buttons = styled(Block)`
  display: flex;
  justify-content: center;
  gap: 24px;
  ${$styles}
`;

export const SubmitButton = styled.button<IStyles & { action: "reject" | "approve" }>`
  cursor: pointer;
  border-radius: 8px;
  border: none;
  padding: 12px 24px;
  width: 100%;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  background: ${({ action }) => {
    switch (true) {
      case action === "reject":
        return "#C84230";
      case action === "approve":
        return "#5EBD3D";
    }
  }};

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.7;
  }

  ${$styles}
`;

export const Description = styled.div<IStyles>`
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${$styles}
`;
