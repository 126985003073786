import React from "react";
import { TheModalBox } from "components";
import {
  $styles,
  Button,
  ButtonBlue,
  DualRingInner,
  DualRingLoader,
  Flex,
  IStyles,
} from "components/common";
import styled, { css } from "styled-components";
import { IAppContext, useAppContext, withApp } from "contexts";
import Image from "next/image";
import Link from "next/link";
import { TheIcon } from "components/TheComponents";
import { useQuery } from "@tanstack/react-query";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { DateTime } from "luxon";

interface KickGuideModalProps {
  onClose: () => void;
}

const styles = css`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 460px;
  @media (min-width: 601px) {
    width: 455px;
  }
  @media (min-width: 1180px) {
    width: 735px;
  }
  @media (max-width: 600px) {
    width: 455px;
  }
  @media (max-width: 450px) {
    width: 355px;
  }
`;

type ConnectionCodeResponse = {
  code: string;
  expire: string;
  result: number;
};

const KickGuideModal: React.FC<KickGuideModalProps> = ({ onClose }) => {
  const { accessFetch } = useAppContext();

  const { data: kickCode, isFetching: isLoading } = useQuery<ConnectionCodeResponse>({
    queryKey: ["connection-code", "kick"],
    queryFn: async () => {
      try {
        const kickCodeResponse = await accessFetch("/user/connection-code?platform=kick");
        const kickCodeData = await kickCodeResponse?.json();
        return kickCodeData;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  });

  const modalBoxProps = {
    hasBlur: true,
    isFixed: true,
    hasBorder: true,
    showClose: false,
    onClose: () => onClose && onClose(),
    styles,
  };

  const onCopy = async (code: string) => {
    try {
      // Clipboard
      if (navigator.clipboard instanceof Clipboard) {
        await navigator.clipboard.writeText(code);
      } else {
        throw new Error("Clipboard undefined!");
      }
    } catch (error) {
      console.error("Could not copy text: ", error);
    }
  };

  const getTimer = (expire: string) => {
    const now = DateTime.local().toUTC();
    const codeExpiryTime = DateTime.fromISO(expire, {
      zone: "Europe/Berlin",
    }).toUTC();

    const timeDifferenceInSeconds = Math.abs(codeExpiryTime.diff(now, "seconds").seconds);

    return timeDifferenceInSeconds;
  };

  return (
    <TheModalBox {...modalBoxProps}>
      <Flex width="100%" justify="center" column>
        {isLoading ? (
          <Flex width="100%" align="center" justify="center" padding="60px 0px">
            <DualRingLoader>
              <DualRingInner />
            </DualRingLoader>
          </Flex>
        ) : (
          <>
            {kickCode?.code && kickCode.expire ? (
              <Flex column width="100%">
                <Flex style={{ marginBottom: 20 }} align="center" justify="start">
                  <Image
                    style={{ marginRight: 20 }}
                    src="/images/icons/kick.png"
                    width={16}
                    height={16}
                    alt="Gamdom"
                  />
                  <Title>Connect kick account</Title>
                </Flex>
                <Flex
                  column
                  width="100%"
                  justify="center"
                  align="center"
                  style={{ margin: "20px 0px" }}
                >
                  <CountdownCircleTimer
                    isPlaying
                    duration={+getTimer(kickCode.expire)}
                    colors={["#5ebd3d", "#9c392f"]}
                    colorsTime={[10, 0]}
                    size={250}
                    strokeWidth={16}
                    trailColor="#244773"
                    onComplete={() => ({ shouldRepeat: true, delay: 1 })}
                  >
                    {({ remainingTime }) => remainingTime}
                  </CountdownCircleTimer>
                </Flex>
                <Flex width="100%" justify="center" align="center" column>
                  <Text
                    styles={css`
                      text-align: center;
                      margin-bottom: 10px;
                    `}
                  >
                    Your verification code
                  </Text>
                  <CopyCode>
                    <CopyLink
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        onCopy(kickCode.code);
                      }}
                    >
                      <TheIcon icon="copy" size={50} />
                    </CopyLink>
                    {kickCode.code}
                  </CopyCode>
                </Flex>
                <Flex style={{ margin: "30px 0px" }} column>
                  <Text>
                    1. Go to our verification{" "}
                    <a
                      href="https://kick.com/watchgamestv"
                      target="_blank"
                      style={{ color: "#5ebd3d" }}
                    >
                      KICK channel
                    </a>{" "}
                  </Text>
                  <Text>2. Type the following message in chat:</Text>
                </Flex>
                <Flex
                  width="100%"
                  justify="center"
                  align="center"
                  style={{ marginBottom: 20 }}
                  column
                >
                  <CopyCode>
                    {" "}
                    <CopyLink
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        onCopy(`!connect ${kickCode.code}`);
                      }}
                    >
                      <TheIcon icon="copy" size={50} />
                    </CopyLink>
                    !connect {kickCode.code}
                  </CopyCode>
                </Flex>
                <Flex style={{ marginTop: 15 }} width="100%" justify="space-between">
                  <BoxItem
                    styles={css`
                      margin-top: 8px;
                    `}
                  >
                    <Button
                      onClick={() => onClose()}
                      styles={css`
                        cursor: pointer;
                        heigth: 50px;
                      `}
                      preset="blue"
                      isDark
                    >
                      <ButtonBlue isDark>Close</ButtonBlue>
                    </Button>
                  </BoxItem>
                </Flex>
              </Flex>
            ) : (
              <h1>No code</h1>
            )}
          </>
        )}
      </Flex>
    </TheModalBox>
  );
};

export default KickGuideModal;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;
  ${$styles}
`;

const Title = styled.div<IStyles>`
  box-sizing: border-box;
  color: #d2eaff;
  font-family: "Roboto";
  font-size: 22px;
  text-align: center;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;
  white-space: nowrap;
  @media (max-width: 450px) {
    font-size: 18px;
  }

  ${$styles}
`;

const Text = styled.div<IStyles>`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;

  ${$styles}
`;

const CopyCode = styled.div<IStyles>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 20px;
  padding-right: 60px;
  align-items: center;
  gap: 8px;
  height: 52px;
  color: rgba(94, 189, 61, 1);
  // background & border
  background: rgba(36, 39, 70, 0.5);
  border: 1px solid rgba(36, 39, 70, 0.5);
  border-radius: 8px;

  // text
  font-weight: 600;
  line-height: 150%;

  ${({ styles }) => styles}
`;

const CopyLink = styled(Link)`
  position: absolute;
  right: 0;
  top: 0;
  display: inline-flex;
`;
