import React from "react";
import { TheModalBox } from "components";
import { $styles, Button, ButtonBlue, Flex, IStyles } from "components/common";
import { TheFieldset } from "components/TheComponents";
import styled, { css } from "styled-components";
import { useAppContext } from "contexts";
import { triggerNotification } from "components/TheComponents/Notification";
import { addCommasToNumber } from "helpers/addCommasToNumber";
import VerifierProvider, { useVerifierContext } from "contexts/VerifierProvider";

interface UpdateBalanceModalProps {
  onClose: () => void;
  refetch: () => void;
  userId: string;
  userBalance: number;
}

interface UpdateBalanceInterface {
  payload: string;
}

const UpdateBalanceModal: React.FC<UpdateBalanceModalProps> = ({
  onClose,
  refetch,
  userBalance,
  userId,
}): React.ReactElement => {
  const { refetchProfile: updateProfile, profile, accessFetch } = useAppContext();
  const { verify, closeMfe } = useVerifierContext();

  const [payload, setPayload] = React.useState(userBalance?.toString() ?? "0");
  const styles = css`
    position: fixed;
    left: 230px;
    top: -40px;
  `;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const numProps = { pattern: "^[d]*$", type: "text" };

  const [errors, setErrors] = React.useState<{
    [key in keyof UpdateBalanceInterface]?: string;
  }>({});

  const validateForm = () => {
    const newErrors: { [key in keyof UpdateBalanceInterface]?: string } = {};

    if (!payload || !/^\d+$/.test(payload)) {
      newErrors.payload = "Points should be number";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const profileId = profile?.id;

  const modalBoxProps = {
    hasBlur: false,
    isFixed: true,
    hasBorder: true,
    showClose: false,
    onClose: () => onClose && onClose(),
    boxProps: {
      styles,
    },
  };

  const updateBalance = async ({ otp }: { otp: string }) => {
    try {
      setIsLoading(true);
      const response = await accessFetch(`/user/${userId}/points`, {
        method: "PUT",
        headers: {
          "x-otp": otp,
        },
        body: JSON.stringify({
          newBalance: +payload,
        }),
      });
      const data = await response?.json();
      if (response && (response.status === 200 || response.status === 201)) {
        triggerNotification({ text: "Points added!", type: "success" });
        refetch();

        if (profileId === userId) {
          updateProfile();
        }
        onClose();
        closeMfe();
      } else {
        triggerNotification({ text: data?.message, type: "error" });
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
    }
  };

  //

  return (
    <TheModalBox
      styles={css`
        padding: 35px;
      `}
      width={375}
      {...modalBoxProps}
    >
      <Flex justify="center" column>
        <Title>EDIT USER BALANCE</Title>
        <TheFieldset
          valid={!errors.payload}
          styles={css`
            width: 100%;
            height: 50px;
            line-height: 35px;
            border-radius: 8px;
            background-image: url("/images/PointShopComponent/coin.png");
            background-repeat: no-repeat;
            background-size: 30px 30px;
            background-position: 1rem center;
            text-indent: 2rem;
            margin: 0px;
          `}
        >
          <input
            {...numProps}
            placeholder={errors.payload ? errors.payload : "Points"}
            value={addCommasToNumber(payload.toString())}
            onChange={(e) => {
              const input = e.target.value;
              const digitsOnly = input.replace(/\D/g, ""); // Filter out non-digit characters
              setPayload(digitsOnly);
            }}
          />
        </TheFieldset>
        <Flex style={{ marginTop: 15 }} width="100%" justify="space-between">
          <BoxItem
            styles={css`
              margin-top: 8px;
              margin-right: 12px;
            `}
          >
            <Button
              onClick={() => onClose()}
              styles={css`
                cursor: pointer;
                heigth: 50px;
              `}
              preset="blue"
              isDark
            >
              <ButtonBlue isDark>Cancel</ButtonBlue>
            </Button>
          </BoxItem>
          <BoxItem
            styles={css`
              margin-top: 8px;
            `}
          >
            <Button
              styles={css`
                cursor: pointer;
                heigth: 50px;
              `}
              preset="blue"
              onClick={() => {
                if (validateForm()) {
                  verify(({ otp }) => updateBalance({ otp }));
                } else {
                  triggerNotification({ text: "Validation failed", type: "error" });
                }
              }}
            >
              <ButtonBlue>Confirm</ButtonBlue>
            </Button>
          </BoxItem>
        </Flex>
      </Flex>
    </TheModalBox>
  );
};

const UpdateBalanceModalWrapper: React.FC<UpdateBalanceModalProps> = (props) => {
  return (
    <VerifierProvider>
      <UpdateBalanceModal {...props} />
    </VerifierProvider>
  );
};

export default UpdateBalanceModalWrapper;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;
  ${$styles}
`;

const Title = styled.div<IStyles>`
  box-sizing: border-box;
  color: #d2eaff;
  font-family: "Roboto";
  font-size: 22px;
  text-align: center;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;

  ${$styles}
`;
