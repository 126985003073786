import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { ITheIcon } from "interfaces";

const cssDiscord_Social = css`
  background-image: url("/images/TheIcon/discord.svg");
  background-size: 15.5px 11.37px;
`;

const cssTwitter_Social = css`
  background-image: url("/images/TheIcon/twitter.svg");
  background-size: 16px 13px;
`;

const cssYoutube_Social = css`
  background-image: url("/images/TheIcon/youtube.svg");
  background-size: 16px 16px;
`;

const cssKick_Social = css`
  background-image: url("/images/TheIcon/kick.svg");
  background-size: 16px 16px;
`;

const cssTwitch_Social = css`
  background-image: url("/images/TheIcon/twitch.svg");
  background-size: 16px 16px;
`;

const cssIntstagram_Social = css`
  background-image: url("/images/TheIcon/instagram.svg");
  background-size: 16px 16px;
`;

const cssPlus = css`
  background-image: url("/images/TheIcon/plus.svg");
  background-size: 16px 16px;
`;

const cssCopy = css`
  background-image: url("/images/TheIcon/copy.svg");
  background-size: 49px 50px;
`;

const cssGift_title = css`
  background-image: url("/images/TheIcon/giftbox-1.svg");
  background-size: 84px 88px;
`;

const cssTwitch_title = css`
  background-image: url("/images/TheIcon/icontwitch16px.svg");
  background-size: 86.33px 88px;
`;

const cssTwitch_small_violet = css`
  background-image: url("/images/TheIcon/twitch-small-violet.svg");
  background-size: 15.33px 16px;
`;

const cssVideo_title = css`
  background-image: url("/images/TheIcon/video-icon.svg");
  background-size: 88px 78px;
`;

const cssCoin_small = css`
  background-image: url("/images/TheIcon/rollwardd-coin@2x.png");
  background-size: 16px 16px;
`;

const cssStar_small = css`
  background-image: url("/images/TheIcon/star-icon.svg");
  background-size: 16px 16px;
`;

const cssStar_blue_small = css`
  background-image: url("/images/TheIcon/star-blue.svg");
  background-size: 16px 16px;
`;

const cssYoutube_small = css`
  background-image: url("/images/TheIcon/iconyoutube16px.svg");
  background-size: 16px 11.79px;
`;

const cssCube_small = css`
  background-image: url("/images/TheIcon/cube-small.svg");
  background-size: 32px 32px;
`;

const cssPerson_small = css`
  background-image: url("/images/TheIcon/person-small.svg");
  background-size: 79px 79px;
  /* background-size: 32px 32px; */
`;

const cssArrowLeft_small_active = css`
  background-image: url("/images/TheIcon/arrow-active.svg");
  background-size: 12px 12px;
  transform: rotate(180deg);
`;

const cssArrowLeft_small_unactive = css`
  background-image: url("/images/TheIcon/arrow-unactive.svg");
  background-size: 12px 12px;
`;

const cssArrowRight_small_active = css`
  background-image: url("/images/TheIcon/arrow-active.svg");
  background-size: 12px 12px;
`;

const cssArrowRight_small_unactive = css`
  background-image: url("/images/TheIcon/arrow-unactive.svg");
  background-size: 12px 12px;
  transform: rotate(180deg);
`;

const cssSpoiler_up = css`
  background-image: url("/images/TheIcon/spoiler-up.svg");
  background-size: 56px 55px;
`;

const cssSpoiler_down = css`
  background-image: url("/images/TheIcon/spoiler-down.svg");
  background-size: 12px 12px;
`;

const cssCheckbox_checked_dark = css`
  background-image: url("/images/TheIcon/checkbox-checked-dark.svg");
  background-size: 12px 12px;
`;

const cssCheckbox_checked_light = css`
  background-image: url("/images/TheIcon/checkbox-checked-light.svg");
  background-size: 12px 8.8px;
`;

const cssEdit = css`
  background-image: url("/images/TheIcon/edit.svg");
  background-size: 16px 16px;
`;

const cssFlip = css`
  background-image: url("/images/TheIcon/flip.svg");
  background-size: 18px 18px;
`;

const cssSave = css`
  background-image: url("/images/TheIcon/save.svg");
  background-size: 14px 14px;
`;

const cssWCrown_40x40 = css`
  background-image: url("/images/TheIcon/w-crown-40x40.svg");
  background-size: 87px 79px; // view: 40px 40px
`;

const cssLogOut = css`
  background-image: url("/images/TheIcon/logOut.svg");
  background-size: 16px 16px;
`;

const cssActionReset = css`
  background-image: url("/images/TheIcon/reset.svg");
  background-size: 16.67px 16.67px;
`;

const cssActionRemove = css`
  background-image: url("/images/TheIcon/action-remove.svg");
  background-size: 16.67px 16.67px;
`;

const cssActionRemoveBlue = css`
  background-image: url("/images/TheIcon/action-remove-blue.svg");
  background-size: 16.67px 16.67px;
`;

const cssActionEdit = css`
  background-image: url("/images/TheIcon/action-edit.svg");
  background-size: 14.25px 14.25px;
`;

const cssMute = css`
  background-image: url("/images/TheIcon/mute.svg");
  background-size: 16.67px 16.67px;
`;

const cssUnmute = css`
  background-image: url("/images/TheIcon/unmute.svg");
  background-size: 16.67px 16.67px;
`;

export const Icon = styled.div<ITheIcon>`
  display: inline-block;
  width: ${({ size, width }) => width ?? size}px;
  height: ${({ size, height }) => height ?? size}px;

  background-clip: border-box;
  background-origin: border-box;
  background-position: center;
  background-repeat: no-repeat;
  ${({ icon }) => {
    const iconStyles: Record<string, FlattenSimpleInterpolation> = {
      "discord:social": cssDiscord_Social,
      "kick:social": cssKick_Social,
      "twitch:social": cssTwitch_Social,
      "instagram:social": cssIntstagram_Social,
      "twitter:social": cssTwitter_Social,
      "youtube:social": cssYoutube_Social,
      plus: cssPlus,
      copy: cssCopy,
      "gift:title": cssGift_title,
      "video:title": cssVideo_title,
      "twitch:title": cssTwitch_title,
      "twitch:small:violet": cssTwitch_small_violet,
      "coin:small": cssCoin_small,
      "star:small": cssStar_small,
      "star:blue": cssStar_blue_small,
      "youtube:small": cssYoutube_small,
      "cube:small": cssCube_small,
      "person:small": cssPerson_small,
      "arrow:small:left:active": cssArrowLeft_small_active,
      "arrow:small:left:unactive": cssArrowLeft_small_unactive,
      "arrow:small:right:active": cssArrowRight_small_active,
      "arrow:small:right:unactive": cssArrowRight_small_unactive,
      "spoiler:up": cssSpoiler_up,
      "spoiler:down": cssSpoiler_down,
      "checkbox:checked:dark": cssCheckbox_checked_dark,
      "checkbox:checked:light": cssCheckbox_checked_light,
      edit: cssEdit,
      flip: cssFlip,
      save: cssSave,
      "wcrown:40": cssWCrown_40x40,
      logout: cssLogOut,
      "action:remove": cssActionRemove,
      "action:remove:blue": cssActionRemoveBlue,
      "action:edit": cssActionEdit,
      "action:reset": cssActionReset,
      mute: cssMute,
      unmute: cssUnmute,
    };

    return (iconStyles as Record<string, FlattenSimpleInterpolation>)[icon] || null;
  }}

  ${({ styles }) => styles}
`;

export function TheIcon({ icon, size = 16, ...props }: ITheIcon): React.ReactElement {
  const iconProps = { icon, size, ...props };

  return <Icon {...iconProps} />;
}
