import React from "react";
import styled, { css } from "styled-components";
import { $styles, IStyles, ITheTableCell, TheTable } from "components";
import * as luxon from "luxon";
import { DualRingInner, DualRingLoader, Flex } from "components/common";
import Image from "next/image";
import { formatNumber } from "components/pachinko/Prizes";

interface Raffle {
  prizeInDollars: number;
  entryCost: number;
  winnersCount: number;
  deadline: string;
  id: number;
}

interface UserRaffleEntry {
  userId: string;
  createdAt: string;
  updatedAt: string;
  isWinner: boolean;
  raffleId: number;
  entryNumber: number;
  id: number;
  raffle: Raffle;
}

export type RafflesTableOptions = {};

export interface ProfileRafflesTableProps {
  $keys?: string[];
  entries?: UserRaffleEntry[];
  skip: number;
  take: number;
  isLoading: boolean;
}

export const ProfileRafflesTable: React.FC<ProfileRafflesTableProps> = ({
  $keys = ["id", "createdAt", "deadline", "entryCost", "isWinner", "prizeInDollars"],
  entries,
  isLoading,
}): React.ReactElement | null => {
  const alignLeft = css`
    text-align: left;
  `;
  const setup = React.useMemo(
    () => [
      {
        $key: "id",
        render: (props: ITheTableCell<UserRaffleEntry, RafflesTableOptions>) => {
          const id: number | undefined = props.cell;
          return <Prize style={{ color: "#A1B4C8" }}>#{id}</Prize>;
        },
        title: null,
      },
      {
        $key: "createdAt",
        render: (props: ITheTableCell<UserRaffleEntry, RafflesTableOptions>) => {
          const createdAt: string = props.cell;
          return (
            <Prize styles={alignLeft} style={{ color: "#A1B4C8" }}>
              {luxon.DateTime.fromISO(createdAt).toFormat("dd.MM.yyyy")}
            </Prize>
          );
        },
        title: (
          <Title>
            <Prize styles={alignLeft}>Entered</Prize>
          </Title>
        ),
      },
      {
        $key: "deadline",
        render: (props: ITheTableCell<UserRaffleEntry, RafflesTableOptions>) => {
          const item: UserRaffleEntry | null = props.row;

          if (!item) {
            return "N/A";
          }

          const deadline = luxon.DateTime.fromISO(item.raffle.deadline);
          const currentDate = luxon.DateTime.local();

          return (
            <Prize styles={alignLeft} style={{ color: "#A1B4C8" }}>
              {deadline < currentDate ? deadline.toFormat("dd.MM.yyyy") : "Live"}
            </Prize>
          );
        },
        title: (
          <Title>
            <Prize styles={alignLeft}>Ended</Prize>
          </Title>
        ),
      },

      {
        $key: "entryCost",
        render: (props: ITheTableCell<UserRaffleEntry, RafflesTableOptions>) => {
          const item: UserRaffleEntry | null = props.row;
          return item ? (
            <Flex width="100%" justify="flex-start">
              <Image src="/images/PointShopComponent/coin.png" height={30} width={30} alt="image" />
              <Prize>{formatNumber(item.raffle.entryCost ?? 10230)}</Prize>
            </Flex>
          ) : (
            "N/A"
          );
        },
        title: (
          <Title>
            <Prize styles={alignLeft}>Price</Prize>
          </Title>
        ),
      },
      {
        $key: "isWinner",
        render: (props: ITheTableCell<UserRaffleEntry, RafflesTableOptions>) => {
          const item: UserRaffleEntry | null = props.row;
          const isWinner = item?.isWinner;
          if (!item) {
            return "N/A";
          }
          const deadline = luxon.DateTime.fromISO(item.raffle.deadline);
          const currentDate = luxon.DateTime.local();

          return (
            <Prize
              styles={alignLeft}
              style={{
                color: deadline < currentDate ? (isWinner ? "#448237" : "#9C392F") : "#3b8be9",
              }}
            >
              {deadline < currentDate ? (isWinner ? "Won" : "Lost") : "Live"}
            </Prize>
          );
        },
        // TBD
        title: <Title styles={alignLeft}>Result</Title>,
      },
      {
        $key: "prizeInDollars",
        render: (props: ITheTableCell<UserRaffleEntry, RafflesTableOptions>) => {
          const item: UserRaffleEntry | null = props.row;
          const raffle = item?.raffle;
          return raffle ? (
            <Prize styles={alignLeft} data-value>
              {parseFloat((raffle?.prizeInDollars / raffle?.winnersCount)?.toFixed(2))}
            </Prize>
          ) : (
            "N/A"
          );
        },
        title: <Title styles={alignLeft}>Prize</Title>,
      },
    ],
    [alignLeft]
  );

  return (
    <Box>
      {isLoading ? (
        <Flex align="center" justify="center" padding="60px 0px">
          <DualRingLoader>
            <DualRingInner />
          </DualRingLoader>
        </Flex>
      ) : (
        <div style={{ overflowX: "auto" }}>
          <TheTable<UserRaffleEntry, RafflesTableOptions>
            $keys={$keys}
            $setup={setup}
            rows={entries ?? []}
            boxStyles={css`
              margin: 0px !important;
            `}
            styles={css`
              border-spacing: 0 5px;
            `}
          />
        </div>
      )}
    </Box>
  );
};

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  text-align: left;
  ${$styles}
`;

const Title = styled.div`
  color: rgba(210, 234, 255, 0.75);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  ${$styles}
`;

const Prize = styled.div<IStyles>`
  font-family: "Rubik";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  &[data-value] {
    color: #5ebd3d;
  }
  &[data-value]::before {
    content: "$";
  }
  ${$styles}
`;
