import React from "react";
import { Button, ButtonBlue, Flex } from "components/common";
import Image from "next/image";
import { BoxItem } from "../ProfileLayout";
import { css } from "styled-components";
import { TheFieldset, TheIcon } from "components/TheComponents";
import { TAppAccessFetch } from "contexts";
import { validate } from "multicoin-address-validator";
import { triggerNotification } from "components/TheComponents/Notification";

export interface AddressInputProps {
  inputBg: string;
  placeholder: string;
  userId?: string;
  field: string;
  currency: string;
  isEditable: boolean;
  addressValue?: any;
  accessFetch: TAppAccessFetch;
  refetchUser: () => void;
}

export const AddressInput: React.FC<AddressInputProps> = ({
  inputBg,
  placeholder,
  userId,
  refetchUser,
  accessFetch,
  isEditable,
  field,
  currency,
  addressValue,
}): React.ReactElement => {
  const [address, setAddress] = React.useState(addressValue || "");
  const [isEditMode, setIsEditMode] = React.useState(false);

  React.useEffect(() => {
    setAddress(addressValue || "");
  }, [addressValue]);

  const updateInput = React.useCallback(
    async (value: string | null) => {
      try {
        const response = await accessFetch(`/user/${userId}/crypto-addresses`, {
          method: "PATCH",
          body: JSON.stringify({
            [field]: value,
          }),
        });
        if (response && (response.status === 200 || response.status === 201)) {
          triggerNotification({ type: "success", text: "User updated" });
          refetchUser();
        } else {
          triggerNotification({ type: "error", text: "Something went wrong" });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [accessFetch, userId, field, refetchUser]
  );

  return (
    <>
      <BoxItem
        styles={css`
          flex-grow: 1;
        `}
      >
        <TheFieldset
          styles={css`
            border: ${isEditMode ? "1px #3b8be9 solid" : "1px #1e213a solid"};
            height: 50px;
            margin-bottom: 10px;
            line-height: 35px;
            border-radius: 8px;
            background-image: url(${inputBg});
            background-repeat: no-repeat;
            background-size: 47px 16px;
            background-position: 1rem center;
          `}
        >
          <Flex align="center" justify="center">
            <input
              style={{ padding: "4px 0px 0px 4rem" }}
              type="text"
              placeholder={placeholder}
              disabled={!isEditMode}
              value={address}
              onChange={(event) => {
                setAddress(event.target.value);
              }}
            />
            {isEditable ? (
              !isEditMode ? (
                <Button
                  styles={css`
                    box-shadow: none;
                    padding: 5px;
                    margin-top: 7px;
                    background: #243a62;
                    transition: background 0.3s ease-in-out;
                    &:hover {
                      background: #1c2636;
                    }
                  `}
                  onClick={() => setIsEditMode(true)}
                >
                  <TheIcon icon="edit" size={16} />
                </Button>
              ) : (
                <Flex>
                  <Button
                    styles={css`
                      box-shadow: none;
                      padding: 5px;
                      height: 26px;
                      background: #243a62;
                      transition: background 0.3s ease-in-out;
                      margin-top: 7px;
                      &:hover {
                        background: #1c2636;
                      }
                    `}
                    onClick={() => {
                      if (address && validate(address, currency)) {
                        setIsEditMode(false);
                        updateInput(address);
                      } else {
                        triggerNotification({
                          type: "error",
                          text: "Address validation error",
                        });
                      }
                    }}
                  >
                    <ButtonBlue style={{ color: "rgba(59, 139, 233, 1)", fontSize: 13 }} isDark>
                      {" "}
                      <Image
                        style={{ marginTop: 2 }}
                        src="/images/TheModalBox/check.png"
                        height={18}
                        width={15}
                        alt="image"
                      />
                      {"Save"}
                    </ButtonBlue>
                  </Button>
                  {addressValue ? (
                    <Button
                      styles={css`
                        box-shadow: none;
                        padding: 5px;
                        height: 26px;
                        background: #243a62;
                        margin-left: 4px;
                        transition: background 0.3s ease-in-out;
                        margin-top: 6px;
                        &:hover {
                          background: #1c2636;
                        }
                      `}
                      onClick={() => {
                        setIsEditMode(false);
                        updateInput(null);
                      }}
                    >
                      <TheIcon icon="action:remove:blue" size={16} />
                    </Button>
                  ) : null}
                </Flex>
              )
            ) : null}
          </Flex>
        </TheFieldset>
      </BoxItem>
    </>
  );
};
