import React, { Dispatch, SetStateAction } from "react";

import { Button, ButtonBlue, Flex } from "components/common";
import Image from "next/image";
import { css } from "styled-components";
import { TheFieldset } from "components/TheComponents";
import { BoxItem } from "../ProfileLayout";
import { SubHeader } from "../ProfileLayout";
import MFAModal from "components/ScreenComponent/MFAModals/MFAModal";
import { triggerNotification } from "components/TheComponents/Notification";

export interface HypedropInputProps {
  hypedropUsername?: string | null;
  isEditable: boolean;
  isOwnProfile: boolean;
  isUnlinkLoading: boolean;
  isMfaEnabled: boolean;
  setShowHypedropGuideModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowQrModal: React.Dispatch<React.SetStateAction<boolean>>;
  getConnections: () => void;
  platformToUnlink: string | null;
  setPlatformToUnlink: React.Dispatch<React.SetStateAction<string | null>>;
  unlinkPlatform: ({ platform, otp }: { platform: string; otp: string }) => Promise<void>;
  showVerifyModal: boolean;
  setShowVerifyModal: Dispatch<SetStateAction<boolean>>;
  isPlatformCanConnect: (platform: string) => Promise<boolean>;
}

const HypedropInput: React.FC<HypedropInputProps> = ({
  hypedropUsername,
  isEditable,
  isOwnProfile,
  setShowHypedropGuideModal,
  getConnections,
  unlinkPlatform,
  setShowQrModal,
  isMfaEnabled,
  isUnlinkLoading,
  platformToUnlink,
  setPlatformToUnlink,
  showVerifyModal,
  setShowVerifyModal,
  isPlatformCanConnect,
}): React.ReactElement => {
  const platform = "Hypedrop";
  return (
    <BoxItem
      styles={css`
        flex-grow: 1;
        display: flex;
        justify-content: center;
        gap: 10px;
        align-items: center;
      `}
    >
      <TheFieldset
        title="Hypedrop ID"
        styles={css`
          width: 100%;
          border: 1px #1e213a solid;
          height: 60px;
          margin-bottom: 10px;
          line-height: 35px;
          border-radius: 8px;
          background-image: url("/images/TheModalBox/hyperdrop.png");
          background-repeat: no-repeat;
          background-size: 27px 27px;
          background-position: 1rem center;
        `}
      >
        <Flex align="center" justify="center">
          <input
            style={{ padding: "3px 0px 0px 3rem" }}
            type="text"
            placeholder="Hypedrop ID"
            disabled
            value={hypedropUsername || ""}
          />
          {!hypedropUsername && isOwnProfile ? (
            <div
              onClick={async () => {
                const isCanConnect = await isPlatformCanConnect("hypedrop");
                if (isCanConnect) {
                  if (process.env.NEXT_PUBLIC_IS_HYPEDROP_API_WORKING === "true") {
                    setShowHypedropGuideModal(true);
                  } else {
                    triggerNotification({
                      text: "Hypedrop verification is currently not available",
                      type: "error",
                    });
                  }
                }
              }}
              style={{
                position: "absolute",
                right: 25,
                top: -4,
                cursor: "pointer",
                color: "rgba(210,234,255,0.5)",
              }}
            >
              Verify
            </div>
          ) : null}
          {hypedropUsername ? (
            <Image
              src="/images/TheIcon/checkbox-checked-light.svg"
              height={15}
              width={15}
              style={{ marginRight: 10 }}
              alt="image"
            />
          ) : null}
        </Flex>
      </TheFieldset>
      {!hypedropUsername ? (
        <Button
          styles={css`
            height: 35px;
            width: 100px;
            padding: 10px 22px;
            margin-bottom: 3px;
          `}
          height={50}
          isDark
          onClick={getConnections}
        >
          <ButtonBlue isDark>
            <SubHeader style={{ fontSize: 12 }}>Recheck</SubHeader>
          </ButtonBlue>
        </Button>
      ) : isEditable ? (
        <Button
          styles={css`
            height: 35px;
            width: 100px;
            padding: 10px 22px;
            margin-bottom: 3px;
          `}
          height={50}
          isDark
          onClick={(e) => {
            e.preventDefault();
            setPlatformToUnlink(platform);
            if (isMfaEnabled) {
              setShowVerifyModal(true);
            } else {
              setShowQrModal(true);
            }
          }}
        >
          <ButtonBlue isDark>
            <SubHeader style={{ fontSize: 12 }}>Unlink</SubHeader>
          </ButtonBlue>
        </Button>
      ) : null}
      {isMfaEnabled && showVerifyModal && platformToUnlink === platform && (
        <MFAModal
          isLoading={isUnlinkLoading}
          onVerify={async (otpObject) => {
            unlinkPlatform({ platform, otp: otpObject.otp });
          }}
          onClose={() => {
            setShowVerifyModal(false);
            setPlatformToUnlink(null);
          }}
        />
      )}
    </BoxItem>
  );
};

export default HypedropInput;
