import React from "react";
import * as luxon from "luxon";
import styled from "styled-components";
import { IStyles, $styles } from "components";
import { Flex } from "components/common";
import Image from "next/image";
import { SubHeader } from "../ItemsTable/ItemsTable";
import { formatNumber } from "components/pachinko/Prizes";
import { IAppContext, withApp } from "contexts";
import TransactionReviewActions from "./TransactionReviewActions";
import { DEFAULT_AVATAR, TRANSACTION_STATUS } from "helpers/constants";
import { IUserDto } from "interfaces";
import { triggerNotification } from "components/TheComponents/Notification";
import { SelectedTransaction } from "../types";
import { ThePointsBalance } from "components/TheComponents/ThePointsBalance";

const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 0px;
  gap: 8px;
  border: 1px solid rgba(218, 248, 255, 0.1);
  width: 100%;

  background: #181f2e;
  border-radius: 8px;
`;

const SubHeaderExtended = styled.h1`
  text-align: center;
  margin: 0;
  white-space: nowrap;
  color: #6c788d;
  padding: 5px;
  padding-left: 0;
  font-weight: 500;
  font-size: 15px;
`;

export const BorderBottomDiv = styled.div`
  border-bottom: 1px solid #1d2431;
  margin: auto;
  width: 100%;
  height: 100%;
`;
interface TransactionsProps {
  onAction: () => void;
  selectedTransaction: SelectedTransaction;
}
interface ReviewLayoutProps {
  transactionsProps: TransactionsProps;
  user: IUserDto;
}

const ReviewModalLayout: React.FC<IAppContext & ReviewLayoutProps> = ({
  accessFetch,
  transactionsProps,
  user,
}): React.ReactElement => {
  const { selectedTransaction, onAction } = transactionsProps;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const actionTransaction = React.useCallback(
    async ({ action, reason }: { action: string; reason: string | undefined }) => {
      setIsLoading(true);
      try {
        const response = await accessFetch(`/point-shop/purchases/${selectedTransaction.id}`, {
          method: "PATCH",

          body: JSON.stringify({
            status: action,
            rejectionReason: action === TRANSACTION_STATUS.REJECTED ? reason : undefined,
          }),
        });
        const data = await response?.json();
        if (response && (response.status === 200 || response.status === 201)) {
          triggerNotification({
            text: `Purchase ${action === TRANSACTION_STATUS.REJECTED ? "Rejected" : "Approved"}`,
            type: "success",
          });
          onAction();
        } else {
          triggerNotification({ text: data?.message, type: "error" });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [accessFetch, selectedTransaction, onAction]
  );

  const colorByStatus = (status: string) => {
    switch (status) {
      case TRANSACTION_STATUS.PENDING:
        return "#3B8BE9";
      case TRANSACTION_STATUS.APPROVED:
        return "#448237";
      case TRANSACTION_STATUS.REJECTED:
        return "#9C392F";
      default:
        return "white";
    }
  };

  return (
    <Flex width="100%" column>
      <Title style={{ fontSize: 16, marginBottom: 10, marginTop: 10 }}>
        {user?.displayName}
        <span style={{ color: "#444e5c" }}> (ID{user?.id})</span>
      </Title>
      <Flex margin="0 0 30px" justify="space-between" width="100%">
        <Flex>
          <Image
            style={{ borderRadius: "50%" }}
            src={user?.profileImageUrl ? user?.profileImageUrl : DEFAULT_AVATAR}
            height={80}
            width={80}
            alt="image"
          />
          <Flex style={{ marginLeft: 15 }} column>
            <SubHeader style={{ fontSize: 12, paddingLeft: 0, color: "#6C788D" }}>
              Joined: {user?.createdAt?.split("T")[0].replaceAll("-", ".")}
            </SubHeader>
            <SubHeader style={{ fontSize: 12, paddingLeft: 0, color: "#6C788D" }}>
              Email: {user?.email || "N/A"}
            </SubHeader>
            <SubHeader style={{ fontSize: 12, paddingLeft: 0, color: "#6C788D" }}>
              Discord ID: {selectedTransaction?.user?.discordUsername || "N/A"}
            </SubHeader>
          </Flex>
        </Flex>

        {/*  */}
        <Flex width="35%" padding="5px" column>
          <Flex width="100%" style={{ marginBottom: 10 }}>
            <SubHeader
              style={{
                fontSize: 16,
                color: "#6C788D",
                height: "100%",
                marginRight: 25,
                paddingLeft: 0,
              }}
            >
              Status:
            </SubHeader>
            <Card style={{ padding: "2px 0px" }}>
              <Flex align="center">
                <SubHeader
                  style={{ fontSize: 12, color: colorByStatus(selectedTransaction.status) }}
                >
                  {selectedTransaction.status}
                </SubHeader>
              </Flex>
            </Card>
          </Flex>
          <Card style={{ padding: "7px 0px" }}>
            <Flex align="center">
              <SubHeader style={{ fontSize: 12, color: "#6C788D" }}>Points balance</SubHeader>
              <Image
                style={{ borderRadius: "50%" }}
                src="/images/PointShopComponent/coin.png"
                height={30}
                width={30}
                alt="image"
              />
              <SubHeader style={{ fontSize: 12 }}>
                <ThePointsBalance value={user?.pointsBalance} />
              </SubHeader>
            </Flex>
          </Card>
        </Flex>

        {/*  */}
      </Flex>
      <BorderBottomDiv />
      <Flex margin="25px 0px" width="100%">
        {/* <Card style={{ height: 60, marginRight: 10, border: "none" }}>
          <Flex style={{ marginLeft: 30 }} width="100%" align="center">
            <Image
              style={{ borderRadius: "50%" }}
              src="/images/PointShopComponent/crown.png"
              height={30}
              width={30}
              alt="image"
            />
            <Flex style={{ marginLeft: 5 }} column>
              <SubHeader style={{ fontSize: 12, color: "#6C788D", fontWeight: 300 }}>
                Leaderboard Wins:
              </SubHeader>
              <SubHeader style={{ fontSize: 15, color: "white" }}>$999</SubHeader>
            </Flex>
          </Flex>
        </Card> */}
        <Card style={{ height: 60, marginRight: 10, border: "none" }}>
          <Flex style={{ marginLeft: 30 }} width="100%" align="center">
            <Image
              style={{ borderRadius: "50%" }}
              src="/images/PointShopComponent/tickets.png"
              height={30}
              width={30}
              alt="image"
            />
            <Flex style={{ marginLeft: 5 }} column>
              <SubHeader style={{ fontSize: 12, color: "#6C788D", fontWeight: 300 }}>
                Raffles won:
              </SubHeader>
              <SubHeader style={{ fontSize: 15, color: "white" }}>{user?.rafflesWon}</SubHeader>
            </Flex>
          </Flex>
        </Card>
        <Card style={{ height: 60, border: "none" }}>
          <Flex style={{ marginLeft: 30 }} width="100%" align="center">
            <Image
              style={{ borderRadius: "50%" }}
              src="/images/PointShopComponent/coin.png"
              height={30}
              width={30}
              alt="image"
            />
            <Flex style={{ marginLeft: 5 }} column>
              <SubHeader style={{ fontSize: 12, color: "#6C788D", fontWeight: 300 }}>
                Points spent:
              </SubHeader>
              <SubHeader style={{ fontSize: 15, color: "white" }}>
                {typeof user?.pointsSpent !== "undefined" ? formatNumber(user?.pointsSpent) : "0"}
              </SubHeader>
            </Flex>
          </Flex>
        </Card>
      </Flex>
      <Flex margin="20px 0 50px" width="100%">
        <Card style={{ width: "35%", marginRight: 20, height: 200 }}>
          <Image
            src={selectedTransaction.pointsShopItem.imageUrl}
            height={180}
            width={180}
            alt="image"
          />
        </Card>
        <Flex width="100%" height={200} column>
          <Flex width="100%" justify="space-between">
            <Title style={{ fontSize: 16, marginBottom: 0 }}>
              {selectedTransaction?.pointsShopItem?.title}
            </Title>
            <SubHeaderExtended>
              {luxon.DateTime.fromISO(selectedTransaction?.pointsShopItem?.createdAt).toFormat(
                "dd.MM.yy"
              )}
            </SubHeaderExtended>
          </Flex>
          <SubHeaderExtended
            style={{
              height: 85,
              overflowY: "scroll",
              width: "70%",
              whiteSpace: "normal",
              textAlign: "left",
            }}
          >
            {selectedTransaction?.pointsShopItem?.description}
          </SubHeaderExtended>
          <Title style={{ marginTop: 5, fontSize: 16 }}>Price</Title>
          <Card style={{ width: "25%", height: 44 }}>
            <Flex align="center">
              <Image
                style={{ borderRadius: "50%" }}
                src="/images/PointShopComponent/coin.png"
                height={30}
                width={30}
                alt="image"
              />
              <SubHeaderExtended>
                {formatNumber(selectedTransaction.pointsShopItem.price)}
              </SubHeaderExtended>
            </Flex>
          </Card>
        </Flex>
      </Flex>
      <BorderBottomDiv />
      {selectedTransaction.status === TRANSACTION_STATUS.REJECTED && (
        <>
          <Flex column margin="10px 0px 20px" width="100%">
            <Title style={{ fontSize: 12, marginBottom: 15, color: "#9C392F" }}>
              Rejection Reason
            </Title>
            <Card style={{ height: 60, border: "none" }}>
              <Flex style={{ marginLeft: 30 }} width="100%" align="center">
                <Flex style={{ marginLeft: 5 }} column>
                  <SubHeader style={{ fontSize: 12, color: "#6C788D", fontWeight: 300 }}>
                    {selectedTransaction.rejectionReason}
                  </SubHeader>
                </Flex>
              </Flex>
            </Card>
          </Flex>
          <TransactionReviewActions
            isLoading={isLoading}
            status={TRANSACTION_STATUS.REJECTED}
            actionTransaction={actionTransaction}
          />
        </>
      )}
      {selectedTransaction.status === TRANSACTION_STATUS.APPROVED && (
        <TransactionReviewActions
          isLoading={isLoading}
          status={TRANSACTION_STATUS.APPROVED}
          actionTransaction={actionTransaction}
        />
      )}
      {selectedTransaction.status === TRANSACTION_STATUS.PENDING && (
        <Flex style={{ margin: "25px 0" }} width="100%" justify="space-between">
          <TransactionReviewActions
            isLoading={isLoading}
            status={TRANSACTION_STATUS.APPROVED}
            actionTransaction={actionTransaction}
            style={{ marginRight: 10 }}
          />
          <TransactionReviewActions
            isLoading={isLoading}
            status={TRANSACTION_STATUS.REJECTED}
            actionTransaction={actionTransaction}
          />
        </Flex>
      )}
    </Flex>
  );
};

export const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;
  ${$styles}
`;

const Title = styled.div<IStyles>`
  box-sizing: border-box;
  color: #d2eaff;
  font-family: "Roboto";
  font-size: 18px;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;

  ${$styles}
`;

export default withApp(ReviewModalLayout);
