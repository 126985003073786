import React from "react";
import { ExchangeRateDto, IUserDto } from "interfaces";
import { Raffle } from "components/raffles/types";
import { Stream } from "helpers/types";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { ApiService } from "services/apiService";
import { ExceptionHandlingType } from "types/apiServiceTypes";
import apiService from "services/apiService";

export type TAppNotifyType = "success" | "error" | "warn" | "info" | "debug";
export type TAppNotifyUnit = "milliseconds" | "seconds" | "minutes" | "hours" | "days";
export type TAppAccessFetch = (
  input: string | URL,
  init?: RequestInit | undefined,
  exceptionHandlingType?: ExceptionHandlingType
) => Promise<Response | void>;
export type TAppAccessURL = (input: string, origin?: string) => URL | void;
export type TAppNotify = (
  text: React.ReactElement[],
  timeout?: number,
  unit?: TAppNotifyUnit
) => void;
export type TAppNotifyAxios = (error: any) => void;
export type TAppNotifyRemove = (...ids: number[]) => void;

export interface IAppNotifyItem {
  id: number;
  type: TAppNotifyType;
  text: React.ReactElement;
  expiresAt?: number;
  timeout?: number;
  unit?: TAppNotifyUnit;
}

export interface BeAGuestDasboard {
  totalParticipants: number;
  probability: number;
}

export interface IAppContext {
  accessFetch: TAppAccessFetch;
  accessURL: TAppAccessURL;
  apiService: ApiService;
  newRaffle: Raffle | null;
  endingRaffle: Raffle | null;
  profile: IUserDto | null;
  isProfileLoading: boolean;
  refetchProfile: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<IUserDto | null, Error>>;
  featureFlags: { code: string; enabled: boolean }[];
  isFeatureEnabled: (code: string) => boolean;
  // setProfile: React.Dispatch<React.SetStateAction<IUserDto | null>>;
  liveStreams: Stream[];
  isAdmin?: boolean;
  showQrModal: boolean;
  setShowQrModal: React.Dispatch<React.SetStateAction<boolean>>;
  rates: ExchangeRateDto | null;
  setRates: React.Dispatch<React.SetStateAction<ExchangeRateDto | null>>;
  profileProps: {
    isProfileOpen: boolean;
    onOpenProfile: () => void;
    onCloseProfile: () => void;
  };
  loginModalProps: {
    isLoginOpen: boolean;
    onOpenLogin: () => void;
    onCloseLogin: () => void;
  };
  onDiscordRedirect: () => void;
  // setUserBalance: React.Dispatch<
  //   React.SetStateAction<Pick<IUserDto, "gPointsBalance" | "pointsBalance">>
  // >;
  setUserBalance: (balance: Pick<IUserDto, "gPointsBalance" | "pointsBalance">) => void;
}

export const AppContext = React.createContext<IAppContext>({
  accessFetch: async () => console.log("AppContext.accessFetch"),
  accessURL: () => console.log("AppContext.accessURL"),
  apiService: apiService,
  onDiscordRedirect: () => console.log("AppContext.onDiscordRedirect"),
  showQrModal: false,
  setShowQrModal: () => console.log("AppContext.setShowLogin"),
  profile: null,
  featureFlags: [],
  isFeatureEnabled: () => false,
  isAdmin: false,
  // setProfile: () => console.log("AppContext.setProfile"),
  newRaffle: null,
  endingRaffle: null,
  liveStreams: [],
  rates: null,
  setRates: () => console.log("AppContext.setRates"),
  profileProps: {
    isProfileOpen: false,
    onOpenProfile: () => console.log("AppContext.onOpenProfile"),
    onCloseProfile: () => console.log("AppContext.onCloseProfile"),
  },
  loginModalProps: {
    isLoginOpen: false,
    onOpenLogin: () => console.log("AppContext.onOpenLogin"),
    onCloseLogin: () => console.log("AppContext.onCloseLogin"),
  },
  isProfileLoading: false,
  refetchProfile: async () => Promise.reject(),
  setUserBalance: () => console.log("AppContext.setUserBalance"),
});
