import React from "react";
import { TheModalBox } from "components";
import {
  $styles,
  Button,
  ButtonBlue,
  DualRingInner,
  DualRingLoader,
  Flex,
  IStyles,
  NoEntitiesText,
} from "components/common";
import { DateTime } from "luxon";
import styled, { css } from "styled-components";
import { IAppContext, withApp } from "contexts";
import Image from "next/image";
import Link from "next/link";
import { TheIcon } from "components/TheComponents";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const styles = css`
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 40px;
  box-sizing: border-box;
  height: 675px;
  @media (min-width: 601px) {
    width: 555px;
  }
  @media (min-width: 1180px) {
    width: 735px;
  }
  @media (max-width: 600px) {
    width: 455px;
  }
  @media (max-width: 450px) {
    width: 355px;
  }
`;

interface HypedropGuideModalProps {
  onClose: () => void;
}

const HypedropGuideModal: React.FC<HypedropGuideModalProps & IAppContext> = ({
  onClose,
  accessFetch,
}): React.ReactElement => {
  const [hypedropCode, setHypedropCode] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [countdown, setCountdown] = React.useState<string | null>(null);

  const modalBoxProps = {
    hasBlur: true,
    isFixed: true,
    hasBorder: true,
    showClose: false,
    onClose: () => onClose && onClose(),
    styles,
  };

  const onCopy = async (code: string) => {
    try {
      // Clipboard
      if (navigator.clipboard instanceof Clipboard) {
        await navigator.clipboard.writeText(code);
      } else {
        throw new Error("Clipboard undefined!");
      }
    } catch (error) {
      console.error("Could not copy text: ", error);
    }
  };

  const getHypedropCode = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const hypedropCodeResponse = await accessFetch("/user/connection-code?platform=hypedrop");
      const hypedropCodeData = await hypedropCodeResponse?.json();
      setHypedropCode(hypedropCodeData?.code);

      const now = DateTime.local().toUTC();
      const codeExpiryTime = DateTime.fromISO(hypedropCodeData.expire, {
        zone: "Europe/Berlin",
      }).toUTC();

      const timeDifferenceInSeconds = Math.abs(codeExpiryTime.diff(now, "seconds").seconds);
      setCountdown(timeDifferenceInSeconds.toString());
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [accessFetch]);

  React.useEffect(() => {
    getHypedropCode(); // Initial data fetch
  }, [getHypedropCode]);

  return (
    <TheModalBox {...modalBoxProps}>
      <Flex width="100%" justify="center" column>
        {isLoading ? (
          <Flex width="100%" align="center" justify="center" padding="60px 0px">
            <DualRingLoader>
              <DualRingInner />
            </DualRingLoader>
          </Flex>
        ) : (
          <>
            {hypedropCode && countdown ? (
              <Flex column width="100%">
                <Flex style={{ marginBottom: 30 }} align="center" justify="start">
                  <Image
                    style={{ marginRight: 20 }}
                    src="/images/icons/hypedrop.png"
                    width={51}
                    height={51}
                    alt="Hypedrop"
                  />
                  <Title>Connect Hypedrop account</Title>
                </Flex>
                <Flex width="100%" justify="center" align="center" column>
                  <CountdownCircleTimer
                    isPlaying
                    duration={+countdown}
                    colors={["#3b8be9", "#9c392f"]}
                    colorsTime={[10, 0]}
                    size={250}
                    strokeWidth={16}
                    trailColor="#244773"
                    onComplete={() => ({ shouldRepeat: true, delay: 1 })}
                  >
                    {({ remainingTime }) => remainingTime}
                  </CountdownCircleTimer>
                  <Text
                    styles={css`
                      text-align: center;
                      margin-bottom: 10px;
                      margin-top: 20px;
                    `}
                  >
                    Your verification code
                  </Text>
                  <CopyCode
                    styles={css`
                      padding-right: 65px;
                    `}
                  >
                    <CopyLink
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        onCopy(hypedropCode);
                      }}
                    >
                      <TheIcon
                        styles={css`
                          margin-left: 10px;
                        `}
                        icon="copy"
                        size={50}
                      />
                    </CopyLink>
                    <Text>{hypedropCode}</Text>
                  </CopyCode>
                </Flex>
                <Flex style={{ margin: "30px 0px" }} column>
                  <Text>
                    1. Go to{" "}
                    <SpanLink href="https://hypedrop.com" target="_blank">
                      Hypedrop
                    </SpanLink>{" "}
                    and open the{" "}
                    <SpanLink href="https://hypedrop.com" target="_blank">
                      chat
                    </SpanLink>{" "}
                  </Text>
                  <Text>2. Type the following message in chat:</Text>
                </Flex>

                <Flex
                  width="100%"
                  justify="center"
                  align="center"
                  style={{ marginBottom: 20 }}
                  column
                >
                  <CopyCode>{hypedropCode}</CopyCode>
                </Flex>
                <Flex style={{ margin: "30px 0px" }} column>
                  <Text>Your verification code becomes invalid after 5 minutes</Text>
                  <Text
                    styles={css`
                      color: rgba(200, 66, 48, 1);
                    `}
                  >
                    2. Do not share this code with anyone
                  </Text>
                </Flex>
                <Flex style={{ marginTop: 15 }} width="100%" justify="space-between">
                  <BoxItem
                    styles={css`
                      margin-top: 8px;
                    `}
                  >
                    <Button
                      onClick={() => onClose()}
                      styles={css`
                        cursor: pointer;
                        heigth: 50px;
                      `}
                      preset="blue"
                      isDark
                    >
                      <ButtonBlue isDark>Close</ButtonBlue>
                    </Button>
                  </BoxItem>
                </Flex>
              </Flex>
            ) : (
              <Flex width="100%" justify="center" align="center">
                <NoEntitiesText>No Code</NoEntitiesText>
              </Flex>
            )}
          </>
        )}
      </Flex>
    </TheModalBox>
  );
};

export default withApp(HypedropGuideModal);

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;
  ${$styles}
`;

const Title = styled.div<IStyles>`
  box-sizing: border-box;
  color: #d2eaff;
  font-family: "Roboto";
  font-size: 22px;
  text-align: center;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;
  white-space: nowrap;
  @media (max-width: 600px) {
    font-size: 16px;
    white-space: normal;
  }

  ${$styles}
`;

const Text = styled.div<IStyles>`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;

  ${$styles}
`;

const CopyCode = styled.div<IStyles>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  gap: 8px;
  height: 52px;
  color: #3b8be9;
  width: fit-content;
  // background & border
  background: rgba(36, 39, 70, 0.5);
  border: 1px solid rgba(36, 39, 70, 0.5);
  border-radius: 8px;

  // text
  font-weight: 600;
  line-height: 150%;

  ${({ styles }) => styles}
`;

const CopyLink = styled(Link)`
  position: absolute;
  right: 0;
  top: 0;
  display: inline-flex;
`;
const SpanLink = styled(Link)`
  color: rgba(59, 139, 233, 1);
  cursor: pointer;
`;
