import React from "react";

import { Button, ButtonBlue, DualRingInner, DualRingLoader } from "components/common";
import { BoxItem } from "./ReviewModalLayout";
import RejectModal from "./RejectModal";
import { IAppContext, withApp } from "contexts";
import { useDisclosure } from "@chakra-ui/react";
import ChakraModal from "components/TheComponents/Modal";
import { TRANSACTION_STATUS } from "helpers/constants";

const TransactionReviewActions = ({
  status,
  actionTransaction,
  style,
  isLoading,
}: {
  status: string;
  isLoading?: boolean;
  actionTransaction: ({
    action,
    reason,
  }: {
    action: string;
    reason: string | undefined;
  }) => Promise<void>;
  style?: object;
} & IAppContext) => {
  const { isOpen: isRejectOpen, onOpen: onOpenReject, onClose: onCloseReject } = useDisclosure();

  const isApproved = status === TRANSACTION_STATUS.APPROVED;

  return (
    <>
      <BoxItem style={{ marginTop: 8 }}>
        <Button
          disabled={!isApproved && !!isLoading}
          style={{
            height: 50,
            boxShadow: "none",
            background: isApproved ? "#9c392f" : "#448237",
            ...style,
          }}
          onClick={() =>
            isApproved
              ? onOpenReject()
              : actionTransaction({ action: TRANSACTION_STATUS.APPROVED, reason: undefined })
          }
        >
          <ButtonBlue>
            {!isApproved && !!isLoading ? (
              <DualRingLoader smallRing>
                <DualRingInner smallRing />
              </DualRingLoader>
            ) : isApproved ? (
              "Reject"
            ) : (
              "Approve"
            )}
          </ButtonBlue>
        </Button>
      </BoxItem>
      <ChakraModal
        isOpen={isRejectOpen}
        onClose={onCloseReject}
        content={
          <RejectModal
            isLoading={!!isLoading}
            actionTransaction={actionTransaction}
            onClose={onCloseReject}
          />
        }
      />
    </>
  );
};

export default withApp(TransactionReviewActions);
