import React from "react";
import { FooterComponent, HeaderComponent } from "components";
import styled from "styled-components";

function PageLayout({ children }: React.PropsWithChildren) {
  return (
    <PageWrapper>
      <HeaderComponent />
      {children}
      <FooterComponent />
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
`;

export default PageLayout;
