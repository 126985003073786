import React from "react";

import { DualRingInner, DualRingLoader, Flex } from "components/common";
import { IUserDto } from "interfaces";
import GamdomGuideModal from "./guides/GamdomGuideModal";
import { IAppContext, withApp } from "contexts";
import KickGuideModal from "./guides/KickGuideModal";
import HypedropGuideModal from "./guides/HypedropGuideModal";
import { triggerNotification } from "components/TheComponents/Notification";
import GamdomInput from "./GamdomInput";
import QRСodeModal from "components/ScreenComponent/MFAModals/QRСodeModal";
import KickButton from "./KickButton";
import DiscordButton from "./DiscordButton";
import HypedropInput from "./HypedropInput";

export interface MainProps {
  user: IUserDto | null;
  isOwnProfile: boolean;
  isAdminCanEdit: boolean;
  refetchUser: () => Promise<void>;
  onDiscordRedirect?: () => void;
  updateInput: ({
    field,
    value,
  }: {
    field: string;
    value: string | number;
  }) => Promise<void>;
}
interface ExternalConnection {
  platform: string;
  username: string;
}

const ConnectionsTab: React.FC<MainProps & IAppContext> = ({
  user,
  isOwnProfile,
  isAdminCanEdit,
  accessFetch,
  refetchUser,
  updateInput,
  showQrModal,
  profile,
  setShowQrModal,
}): React.ReactElement => {
  const isMfaEnabled = !!profile?.mfaSecret;
  const [showVerifyModal, setShowVerifyModal] = React.useState<boolean>(false);
  const userGamodomId = user?.gamdomId || "";
  const [isGamdomEditMode, setIsGamdomEditMode] = React.useState(false);
  const [gamdomId, setGamdomId] = React.useState(userGamodomId);
  const [showGamdomGuideModal, setShowGamdomGuideModal] = React.useState(false);
  const [showKickGuideModal, setShowKickGuideModal] = React.useState(false);
  const [showHypedropGuideModal, setShowHypedropGuideModal] =
    React.useState(false);
  const [externalConnections, setExternalConnections] = React.useState<
    ExternalConnection[]
  >([]);
  const [isUnlinkLoading, setIsUnlinkLoading] = React.useState(false);
  const [isConnectionsLoading, setIsConnectionsLoading] = React.useState(true);
  const [isPlatformConnectionLoading, setIsPlatformConnectionLoading] = React.useState(false);
  const [platformToUnlink, setPlatformToUnlink] = React.useState<string | null>(
    null
  );

  const isPlatformCanConnect = React.useCallback(async (platform: string): Promise<boolean> => {
    try {
      setIsPlatformConnectionLoading(true);
      const hypedropCodeResponse = await accessFetch(`/user/connection-code?platform=${platform}`);
      const hypedropCodeData = await hypedropCodeResponse?.json();
      setIsPlatformConnectionLoading(false);
      if (hypedropCodeData.code === 'bot is busy') {
        const capitalizePlatform = platform.charAt(0).toUpperCase() + platform.slice(1);
        triggerNotification({
          text: `${capitalizePlatform} verification is currently not available`,
          type: "error",
        });
        return false;
      } 
      return true;
    } catch (error) {
      console.error("Error fetching data:", error);
      return false;
    }
  }, [accessFetch]);

  React.useEffect(() => {
    setGamdomId(userGamodomId);
  }, [userGamodomId]);

  const kickUsername = Array.isArray(externalConnections)
    ? externalConnections.find(
        (el: ExternalConnection) => el?.platform === "kick"
      )?.username
    : null;
  const hypedropUsername = Array.isArray(externalConnections)
    ? externalConnections.find(
        (el: ExternalConnection) => el?.platform === "hypedrop"
      )?.username
    : null;

  const userId = user?.id;
  const isDiscordLinked = !!user?.discordId;
  const hasVerifiedRoleOnDiscordGuild = user?.hasVerifiedRoleOnDiscordGuild;
  const userDiscordUsername = user?.discordUsername;

  const getConnections = React.useCallback(async () => {
    try {
      if (userId) {
        const connectionsResponse = await accessFetch(
          `/user/${userId}/get-connections`
        );
        const connectionsData = await connectionsResponse?.json();
        setExternalConnections(connectionsData);
        setIsConnectionsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [accessFetch, userId]);

  const unlinkPlatform = React.useCallback(
    async ({ platform, otp }: { platform: string; otp: string }) => {
      try {
        setIsUnlinkLoading(true);
        const unlinkResponse = await accessFetch(
          `/user/${userId}/unlink-external-platform`,
          {
            method: "PATCH",
            headers: {
              "x-otp": otp,
            },
            body: JSON.stringify({
              platform: platform.toLowerCase(),
            }),
          }
        );
        const json = await unlinkResponse?.json();
        if (
          unlinkResponse &&
          (unlinkResponse.status === 200 || unlinkResponse.status === 201)
        ) {
          triggerNotification({
            text: `${platform} unlinked`,
            type: "success",
          });
          getConnections();
          setShowVerifyModal(false);
          setPlatformToUnlink(null);
        } else {
          triggerNotification({ text: json?.message, type: "error" });
        }
        setIsUnlinkLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [accessFetch, userId, getConnections]
  );

  React.useEffect(() => {
    getConnections(); // Initial data fetch
  }, [getConnections]);

  return (
    <Flex align="center" column width="100%">
      {isConnectionsLoading || isPlatformConnectionLoading ? (
        <Flex align="center" justify="center" padding="60px 0px">
          <DualRingLoader>
            <DualRingInner />
          </DualRingLoader>
        </Flex>
      ) : (
        <>
          <GamdomInput
            isEditable={isAdminCanEdit || isOwnProfile}
            isGamdomEditMode={isGamdomEditMode}
            gamdomId={gamdomId}
            setGamdomId={setGamdomId}
            setShowGamdomGuideModal={setShowGamdomGuideModal}
            updateInput={updateInput}
            setIsGamdomEditMode={setIsGamdomEditMode}
          />
          <HypedropInput
            hypedropUsername={hypedropUsername}
            isEditable={isAdminCanEdit}
            isOwnProfile={isOwnProfile}
            isUnlinkLoading={isUnlinkLoading}
            setShowHypedropGuideModal={setShowHypedropGuideModal}
            getConnections={getConnections}
            unlinkPlatform={unlinkPlatform}
            showVerifyModal={showVerifyModal}
            platformToUnlink={platformToUnlink}
            setPlatformToUnlink={setPlatformToUnlink}
            setShowVerifyModal={setShowVerifyModal}
            isMfaEnabled={isMfaEnabled}
            setShowQrModal={setShowQrModal}
            isPlatformCanConnect={isPlatformCanConnect}
          />
          <Flex width="100%">
            <DiscordButton
              isDiscordLinked={isDiscordLinked}
              hasVerifiedRoleOnDiscordGuild={hasVerifiedRoleOnDiscordGuild}
              userDiscordUsername={userDiscordUsername}
              isEditable={isAdminCanEdit}
              isOwnProfile={isOwnProfile}
              refetchUser={refetchUser}
              userId={userId}
              isMfaEnabled={isMfaEnabled}
            />
            <KickButton
              kickUsername={kickUsername}
              isUnlinkLoading={isUnlinkLoading}
              platformToUnlink={platformToUnlink}
              setPlatformToUnlink={setPlatformToUnlink}
              getConnections={getConnections}
              isOwnProfile={isOwnProfile}
              isEditable={isAdminCanEdit}
              unlinkPlatform={unlinkPlatform}
              setShowKickGuideModal={setShowKickGuideModal}
              isMfaEnabled={isMfaEnabled}
              showVerifyModal={showVerifyModal}
              setShowVerifyModal={setShowVerifyModal}
              isPlatformCanConnect={isPlatformCanConnect}
            />
          </Flex>
        </>
      )}

      {showGamdomGuideModal ? (
        <GamdomGuideModal onClose={() => setShowGamdomGuideModal(false)} />
      ) : null}
      {showKickGuideModal ? (
        <KickGuideModal onClose={() => setShowKickGuideModal(false)} />
      ) : null}
      {showHypedropGuideModal ? (
        <HypedropGuideModal onClose={() => setShowHypedropGuideModal(false)} />
      ) : null}
      {showQrModal && <QRСodeModal onClose={() => setShowQrModal(false)} />}
    </Flex>
  );
};

export default withApp(ConnectionsTab);
