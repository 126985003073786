import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { IStyles, $styles } from "components";
import { DualRingInner, DualRingLoader, Flex } from "components/common";
import { useAppContext } from "contexts";
import { Main } from "./ProfileModalTabs/Main";
import RafflesTab from "./ProfileModalTabs/RafflesTab";
import PointsShopTab from "./ProfileModalTabs/PointsShopTab";
import { IUserDto } from "interfaces";
import ConnectionsTab from "./ProfileModalTabs/ConnectionsTab";
import { triggerNotification } from "components/TheComponents/Notification";
import { ADMIN_ROLES, ALL_ADMIN_ROLES } from "helpers/constants";
import ReviewModalLayout from "components/points-shop/Modals/ReviewModalLayout";
import { SelectedTransaction } from "components/points-shop/types";
import CaseItemsTab from "./ProfileModalTabs/CaseItemsTab";
import Tabs, { ITab, ITabDefault } from "components/common/Tabs/Tabs";
import { headerStyles } from "components/common/Tabs/headerStyles";

const MyBetsTab = dynamic(() => import("./ProfileModalTabs/MyBetsTab"), {
  ssr: false,
});

export enum TABS {
  TRANSACTION = "transaction",
  MAIN = "main",
  RAFFLES = "raffles",
  POINTS_SHOP = "pointsShop",
  CONNECTIONS = "connections",
  CASE_ITEMS = "caseitems",
  MY_BETS = "myBets",
  MY_TRANSACTIONS = "myTransactions",
}

export const Item = styled.div<{ isActive?: boolean }>`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: rgba(210, 234, 255, 0.75);
  white-space: nowrap;
  margin-right: 10px;
  ${({ isActive }) =>
    isActive
      ? css`
          border-bottom: 1px #3b8be9 solid;
        `
      : null}
`;

export const SubHeader = styled.h1`
  text-align: center;
  margin: 0;
  color: #99abbd;
  padding: 5px;
  font-weight: 500;
  font-size: 16px;
  @media (max-width: 1280px) {
    font-size: 13px;
  }
`;

export const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 0px;
  gap: 8px;
  border: 1px solid rgba(218, 248, 255, 0.1);
  width: 100%;

  background: #181f2e;
  border-radius: 8px;
`;

export const ExtendedCard = styled(Card)<{ isStats?: boolean; isCasino?: boolean }>`
  ${({ isStats }) =>
    isStats &&
    css`
      @media (min-width: 1280px) {
        margin-right: 10px !important;
      }
    `};
  ${({ isCasino }) =>
    isCasino &&
    css`
      @media (min-width: 1280px) {
        margin-right: 20px !important;
      }
    `};
`;

export const SubHeaderExtended = styled.h1`
  text-align: center;
  margin: 0;
  white-space: nowrap;
  color: #6c788d;
  padding: 5px;
  padding-left: 0;
  font-weight: 500;
  font-size: 15px;
`;

export interface User {
  id: string;
  displayName: string;
  profileImageUrl: string;
  offlineImageUrl: string;
  email: string;
  createdAt: string;
  pointsBalance: number;
  pointsSpent: number;
}

export interface AdminProps {
  refetchUsers?: () => void;
  userToEdit?: IUserDto;
}

export interface TransactionsProps {
  onAction: () => void;
  selectedTransaction: SelectedTransaction | null;
}

interface ProfileModalProps {
  refetchUsers?: () => void;
  isMobile: boolean;
  userId: string;
  transactionsProps?: TransactionsProps;
  initialTab?: string;
}

const ProfileLayout: React.FC<ProfileModalProps & AdminProps> = ({
  isMobile,
  refetchUsers,
  userId,
  transactionsProps,
  initialTab,
}): React.ReactElement => {
  const { refetchProfile, onDiscordRedirect, isFeatureEnabled, accessFetch, profile } =
    useAppContext();

  const router = useRouter();
  const initialSelectedTab =
    (!!transactionsProps && TABS.TRANSACTION) ||
    (router.query.selectedTab as string) ||
    initialTab ||
    TABS.MAIN;
  const isOwnProfile = userId === profile?.id;
  const selectionState = React.useState<ITabDefault>({
    key: initialSelectedTab,
    tabTitle: initialSelectedTab,
  });
  const isAdminCanEdit = !!profile?.roles.some(
    (role) => role.name === ALL_ADMIN_ROLES.SUPER_ADMIN || role.name === ALL_ADMIN_ROLES.USERS_ADMIN
  );

  const canSeeRafflesTab = isOwnProfile
    ? true
    : !!profile?.roles.some(
        (role) =>
          role.name === ALL_ADMIN_ROLES.SUPER_ADMIN ||
          role.name === ALL_ADMIN_ROLES.RAFFLE_ADMIN ||
          role.name === ALL_ADMIN_ROLES.USERS_ADMIN
      );

  const canSeePointsShopTab = isOwnProfile
    ? true
    : !!profile?.roles.some(
        (role) =>
          role.name === ALL_ADMIN_ROLES.SUPER_ADMIN ||
          role.name === ALL_ADMIN_ROLES.POINTS_SHOP_ADMIN ||
          role.name === ALL_ADMIN_ROLES.USERS_ADMIN
      );

  const canSeeConnectionsTab = isOwnProfile
    ? true
    : !!profile?.roles.some(
        (role) =>
          role.name === ALL_ADMIN_ROLES.SUPER_ADMIN || role.name === ALL_ADMIN_ROLES.USERS_ADMIN
      );

  const [user, setUser] = React.useState<IUserDto | null>(null);

  const fetchUser = React.useCallback(async () => {
    if (isOwnProfile) {
      // need that for refetch own profile
      const { data } = await refetchProfile();
      if (data) {
        setUser(data);
      }
    } else {
      try {
        const userDataResponse = await accessFetch(
          `/user/${userId}?withStats=true&withCrypto=true`
        );
        const userData = await userDataResponse?.json();
        setUser(userData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }, [accessFetch, userId, isOwnProfile]);

  React.useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const updateInput = React.useCallback(
    async ({ field, value }: { field: string; value: string | number }) => {
      try {
        const response = await accessFetch(`/user/${userId}`, {
          method: "PATCH",
          body: JSON.stringify({
            [field]: value,
          }),
        });
        if (response && (response.status === 200 || response.status === 201)) {
          triggerNotification({ type: "success", text: "User updated" });
          fetchUser();
        } else {
          triggerNotification({ type: "error", text: "Something went wrong" });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [accessFetch, userId, fetchUser]
  );

  const tabsList = useMemo(getTabsList, [
    canSeeConnectionsTab,
    canSeePointsShopTab,
    canSeeRafflesTab,
    fetchUser,
    isAdminCanEdit,
    isFeatureEnabled,
    isOwnProfile,
    refetchUsers,
    transactionsProps,
    updateInput,
    user,
    userId,
    profile?.roles,
    accessFetch,
    onDiscordRedirect,
    isMobile,
  ]);

  function getTabsList(): ITab[] {
    const tabsList: ITab[] = [];

    if (!!transactionsProps)
      tabsList.push({
        key: TABS.TRANSACTION.toString(),
        tabTitle: "TRANSACTION",
        tabContent: <ReviewModalLayout user={user} transactionsProps={transactionsProps} />,
      });

    tabsList.push({
      key: TABS.MAIN.toString(),
      tabTitle: "MAIN",
      tabContent: (
        <Main
          isOwnProfile={isOwnProfile}
          isAdminCanEdit={isAdminCanEdit}
          showDiscordId={
            !!profile?.roles.some((userRole) =>
              ADMIN_ROLES.some((adminRole) => adminRole.name === userRole.name)
            )
          }
          updateInput={updateInput}
          refetchUser={fetchUser}
          refetchUsers={refetchUsers}
          accessFetch={accessFetch}
          isMobile={isMobile}
          user={user}
        />
      ),
    });

    if (canSeeRafflesTab)
      tabsList.push({
        key: TABS.RAFFLES.toString(),
        tabTitle: "RAFFLES",
        tabContent: <RafflesTab user={user} />,
      });

    if (canSeePointsShopTab)
      tabsList.push({
        key: TABS.POINTS_SHOP.toString(),
        tabTitle: "POINTS SHOP",
        tabContent: <PointsShopTab user={user} />,
      });

    if (canSeeConnectionsTab)
      tabsList.push({
        key: TABS.CONNECTIONS.toString(),
        tabTitle: "CONNECTIONS",
        tabContent: (
          <ConnectionsTab
            updateInput={updateInput}
            refetchUser={fetchUser}
            isAdminCanEdit={isAdminCanEdit}
            isOwnProfile={isOwnProfile}
            onDiscordRedirect={onDiscordRedirect}
            user={user}
          />
        ),
      });

    if (isFeatureEnabled("ALLOW_FREE_CASES"))
      tabsList.push({
        key: TABS.CASE_ITEMS.toString(),
        tabTitle: "Case Items",
        tabContent: <CaseItemsTab />,
      });
    tabsList.push({
      key: TABS.MY_BETS.toString(),
      tabTitle: isOwnProfile ? "My bets" : "User Bets",
      tabContent: <MyBetsTab userId={userId} />,
    });

    return tabsList;
  }

  return (
    <Flex style={{ overflowX: "clip" }} height="100%" width="100%" column>
      <Title>Profile</Title>
      {!user ? (
        <Flex width="100%" align="center" justify="center" padding="60px 0px">
          <DualRingLoader>
            <DualRingInner />
          </DualRingLoader>
        </Flex>
      ) : (
        <>
          <Tabs tabsList={tabsList} selectedTabActions={selectionState} />
        </>
      )}
    </Flex>
  );
};

export const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;
  ${$styles}
`;

export const Title = styled.div<IStyles>`
  box-sizing: border-box;
  color: #d2eaff;
  font-family: "Roboto";
  font-size: 18px;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;

  ${$styles}
`;

export default ProfileLayout;
