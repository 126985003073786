import styled from "styled-components";

export const MainTilesGrid = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;

  margin: 16px 0;
`;

export const Balances = styled.div`
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  gap: 12px;

  align-self: center;


`;

export const StrictGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr minmax(20px, 24px) 1fr;
  gap: 8px;
  align-items: center;

  @media (max-width: 480px) {
    grid-template-columns: minmax(20px, 24px) 1fr;
  }
`;

export const Tile = styled.div`
  flex-grow: 1;
  padding: 8px 12px;
  border: 1px solid #24274680;
  background: #24274680;
  border-radius: 8px;
  line-height: normal;
  font-family: "Roboto", sans-serif;



  color: #d2eaff80;

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .highlight {
    font-size: 16px;
    color: #d2eaff;
    font-weight: 600;
  }

  .flex {
    width: 100%;
    display: flex;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .justify-start {
    justify-content: flex-start;
  }

  .items-center {
    align-items: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .gap-12 {
    gap: 12px;
  }

  .gap-6 {
    gap: 6px;
  }

  .flex-col {
    flex-direction: column;
  }

  @media (max-width: 480px) {
    padding: 8px ;
    .highlight  {
        font-size: 14px;
    }
  }
`;
