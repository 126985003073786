import React from "react";

import { Button, ButtonBlue, Flex } from "components/common";
import Image from "next/image";
import { css } from "styled-components";
import { TheFieldset, TheIcon } from "components/TheComponents";
import { IAppContext, withApp } from "contexts";
import { BoxItem } from "../ProfileLayout";

export interface HypedropInputProps {
  gamdomId: string | number;
  isEditable: boolean;
  isGamdomEditMode: boolean;
  setGamdomId: React.Dispatch<React.SetStateAction<string | number>>;
  setShowGamdomGuideModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsGamdomEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  updateInput: ({ field, value }: { field: string; value: string | number }) => Promise<void>;
}

const GamdomInput: React.FC<HypedropInputProps & IAppContext> = ({
  isGamdomEditMode,
  gamdomId,
  isEditable,
  setGamdomId,
  setShowGamdomGuideModal,
  updateInput,
  setIsGamdomEditMode,
}): React.ReactElement => {
  return (
    <BoxItem
      styles={css`
        flex-grow: 1;
      `}
    >
      <TheFieldset
        title="Gamdom ID"
        styles={css`
          border: ${isGamdomEditMode ? "1px #3b8be9 solid" : "1px #1e213a solid"};
          height: 60px;
          margin-bottom: 10px;
          line-height: 35px;
          border-radius: 8px;
          background-image: url("/images/TheModalBox/gamdom.png");
          background-repeat: no-repeat;
          background-size: 32px 32px;
          background-position: 1rem center;
        `}
      >
        <Flex align="center" justify="center">
          <input
            style={{ padding: "3px 0px 0px 10px", textIndent: "2rem" }}
            placeholder="Gamdom ID"
            type="number"
            disabled={!isGamdomEditMode}
            value={gamdomId}
            onChange={(e) => {
              const value = e.target.value;
              setGamdomId(+value);
            }}
          />
          {isEditable ? (
            <>
              <div
                onClick={() => setShowGamdomGuideModal(true)}
                style={{
                  position: "absolute",
                  right: isGamdomEditMode ? 80 : 45,
                  top: -4,
                  cursor: "pointer",
                  color: "rgba(210,234,255,0.5)",
                }}
              >
                Find
              </div>
              <Button
                styles={css`
                  box-shadow: none;
                  padding: 5px;
                  height: 26px;
                  background: #243a62;
                  transition: background 0.3s ease-in-out;
                  &:hover {
                    background: #1c2636;
                  }
                `}
              >
                {isGamdomEditMode ? (
                  <ButtonBlue
                    onClick={() => {
                      setIsGamdomEditMode(false);
                      updateInput({ field: "gamdomId", value: gamdomId });
                    }}
                    style={{ color: "rgba(59, 139, 233, 1)", fontSize: 13 }}
                    isDark
                  >
                    <Image
                      style={{ marginTop: 2 }}
                      src="/images/TheModalBox/check.png"
                      height={18}
                      width={15}
                      alt="image"
                    />
                    {"Save"}
                  </ButtonBlue>
                ) : (
                  <ButtonBlue
                    onClick={() => {
                      setIsGamdomEditMode(true);
                    }}
                    style={{ color: "rgba(59, 139, 233, 1)", fontSize: 13 }}
                    isDark
                  >
                    <TheIcon icon="edit" size={16} />
                  </ButtonBlue>
                )}
              </Button>
            </>
          ) : null}
        </Flex>
      </TheFieldset>
    </BoxItem>
  );
};

export default withApp(GamdomInput);
