import React from "react";
import styled, { css } from "styled-components";
import { $stylesReset, IStyles } from "./$styles";

export type TPageType = "first" | "prev" | "page" | "more" | "next" | "last";

export type TPageProps = (props: IPageProps) => Partial<IPageProps>;

export interface IPageProps extends IStyles {
  children?: React.ReactElement | string | number;
  index?: number;
  isCurrent?: boolean;
  isDisabled?: boolean;
  isType?: TPageType;
  title?: string;
  onClick?: (event: React.MouseEvent) => void;
}

export interface IPagesProps extends IStyles {
  name?: string;
  range?: number;
  skip?: number;
  take?: number;
  total?: number;
  showInfo?: boolean;
  onClick?: (index: number) => void;
}

/**
 * Create pagination block
 */
export const Pages: React.FC<IPagesProps> = ({
  name = "?",
  range = 3,
  skip = 0,
  take = 1,
  total = 0,
  showInfo = true,
  onClick,
  styles,
}): React.ReactElement => {
  const pageFirst = 0;
  const pageLast = Math.max(Math.ceil(total / take) - 1, pageFirst);
  const pageCurrent = Math.floor(skip / take);
  const pagePrev = Math.max(pageCurrent - 1, pageFirst);
  const pageNext = Math.min(pageCurrent + 1, pageLast);
  const pageTitle = (index: number) => `Page ${index + 1}`;

  const onPageClick = (index: number) => (event: React.MouseEvent) => {
    event.preventDefault();
    onClick && onClick(index);
  };

  const indexes: number[] = Array(2 * range + 1)
    .fill(0)
    .map((_, index: number) => index + pageCurrent - range)
    .filter((index: number) => pageFirst <= index && index <= pageLast);

  const pages: IPageProps[] = indexes.map((index: number) => ({
    index,
    isCurrent: pageCurrent === index,
    isType: "page",
    title: pageTitle(index),
    onClick: onPageClick(index),
  }));

  const prevProps: IPageProps | null =
    indexes.length > 0 && pagePrev < pageCurrent
      ? { isType: "prev", onClick: onPageClick(pagePrev) }
      : indexes.length > 0
      ? { isDisabled: true, isType: "prev" }
      : null;

  const nextProps: IPageProps | null =
    indexes.length > 0 && pageCurrent < pageNext
      ? { isType: "next", onClick: onPageClick(pageNext) }
      : indexes.length > 0
      ? { isDisabled: true, isType: "next" }
      : null;

  const firstProps: IPageProps | null =
    indexes.length > 0 && pageFirst < Math.min(...indexes)
      ? {
          isType: "first",
          title: pageTitle(pageFirst),
          onClick: onPageClick(pageFirst),
        }
      : null;

  const lastProps: IPageProps | null =
    indexes.length > 0 && Math.max(...indexes) < pageLast
      ? {
          isType: "last",
          title: pageTitle(pageLast),
          onClick: onPageClick(pageLast),
        }
      : null;

  const firstMoreProps: IPageProps | null =
    indexes.length > 0 && pageFirst + 1 < Math.min(...indexes)
      ? { isType: "more", title: "..." }
      : null;

  const lastMoreProps: IPageProps | null =
    indexes.length > 0 && Math.max(...indexes) < pageLast - 1
      ? { isType: "more", title: "..." }
      : null;

  return (
    <Box styles={styles}>
      {showInfo ? (
        <Info>
          <Name>{name}</Name>
          <Total>{total}</Total>
        </Info>
      ) : null}
      <List>
        {prevProps && <Prev {...prevProps}>&lt;</Prev>}
        {firstProps && <Page {...firstProps}>{pageFirst + 1}</Page>}
        {firstMoreProps && <More {...firstMoreProps}>...</More>}
        {pages.map(({ index = 0, isCurrent, title, onClick }) => {
          const pageProps = { isCurrent, title, onClick };
          return (
            <Page key={index} {...pageProps}>
              {index + 1}
            </Page>
          );
        })}
        {lastMoreProps && <More {...lastMoreProps}>...</More>}
        {lastProps && <Page {...lastProps}>{pageLast + 1}</Page>}
        {nextProps && <Next {...nextProps}>&gt;</Next>}
      </List>
    </Box>
  );
};

const Box = styled.div<IStyles>`
  ${$stylesReset}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${({ styles }) => styles}
`;

const Info = styled.div<IStyles>`
  ${$stylesReset}
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  ${({ styles }) => styles}
`;

const Name = styled.div<IStyles>`
  ${$stylesReset}
  display: inline-block;
  color: rgba(210, 234, 255, 0.5);
  font-family: "Roboto";
  line-height: 16px;
  text-transform: uppercase;
  ${({ styles }) => styles}
`;

const Total = styled.div<IStyles>`
  ${$stylesReset}
  display: inline-block;
  color: rgba(210, 234, 255, 0.75);
  font-family: "Rubik";
  line-height: 30px;
  ${({ styles }) => styles}
`;

const List = styled.div<IStyles>`
  ${$stylesReset}
  flex-grow: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  ${({ styles }) => styles}
`;

const Page = styled.div<IStyles & IPageProps>`
  ${$stylesReset}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 6px;
  color: rgba(210, 234, 255, 0.75);
  font-family: "Rubik";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  ${({ isCurrent, isDisabled, onClick }) =>
    isCurrent && !isDisabled && onClick
      ? css`
          border-color: #3b8be9 !important;
          cursor: pointer;
        `
      : !isDisabled && onClick
      ? css`
          color: #d2eaff;
          &:hover {
            border-color: rgba(210, 234, 255, 0.1);
          }
        `
      : null}
  ${({ styles }) => styles}
`;

const Prev = styled(Page)`
  margin-right: 11px;
  width: 24px;
  height: 24px;
  background-color: rgba(35, 163, 255, 0.05);
  border-radius: 8px;
  color: rgba(210, 234, 255, 0.5);
`;

const More = styled(Page)`
  color: rgba(210, 234, 255, 0.5);
`;

const Next = styled(Page)`
  margin-left: 11px;
  width: 24px;
  height: 24px;
  background-color: rgba(35, 163, 255, 0.05);
  border-radius: 8px;
  color: rgba(210, 234, 255, 0.5);
`;
