import React from "react";
import { Button, ButtonBlue, Flex } from "components/common";
import Image from "next/image";
import { BoxItem } from "../ProfileLayout";
import { css } from "styled-components";
import { TheFieldset, TheIcon } from "components/TheComponents";
import { triggerNotification } from "components/TheComponents/Notification";

export interface SteamLinkInputProps {
  steamTradeLink: string;
  updateInput: ({ field, value }: { field: string; value: string | number }) => Promise<void>;
  isEditable: boolean;
}

export const SteamLinkInput: React.FC<SteamLinkInputProps> = ({
  updateInput,
  steamTradeLink,
  isEditable,
}): React.ReactElement => {
  const [link, setLink] = React.useState(steamTradeLink);
  const [isEditMode, setIsEditMode] = React.useState(false);

  React.useEffect(() => {
    setLink(steamTradeLink);
  }, [steamTradeLink]);

  return (
    <>
      <BoxItem
        styles={css`
          flex-grow: 1;
        `}
      >
        <TheFieldset
          styles={css`
            border: ${isEditMode ? "1px #3b8be9 solid" : "1px #1e213a solid"};
            height: 50px;
            margin-bottom: 10px;
            line-height: 35px;
            border-radius: 8px;
            background-image: url("/images/icons/steam.png");
            background-repeat: no-repeat;
            background-size: 25px 25px;
            background-position: 1rem center;
          `}
        >
          <Flex align="center" justify="center">
            <input
              style={{ padding: "8px 70px 0px 3rem" }}
              type="text"
              placeholder="Steam Trade URL..."
              disabled={!isEditMode}
              value={link}
              onChange={(event) => {
                setLink(event.target.value);
              }}
            />
            {isEditable ? (
              <a
                target="_blank"
                href="https://steamcommunity.com/id/me/tradeoffers/privacy"
                rel="noopener noreferrer"
              >
                <div
                  style={{
                    position: "absolute",
                    right: isEditMode ? 80 : 45,
                    top: 1,
                  }}
                >
                  Find
                </div>
              </a>
            ) : null}

            {isEditable ? (
              !isEditMode ? (
                <Button
                  styles={css`
                    box-shadow: none;
                    padding: 5px;
                    margin-top: 6px;
                    background: #243a62;
                    transition: background 0.3s ease-in-out;
                    &:hover {
                      background: #1c2636;
                    }
                  `}
                  onClick={() => setIsEditMode(true)}
                >
                  <TheIcon icon="edit" size={16} />
                </Button>
              ) : (
                <Button
                  styles={css`
                    box-shadow: none;
                    padding: 5px;
                    height: 26px;
                    background: #243a62;
                    transition: background 0.3s ease-in-out;
                    margin-top: 6px;
                    &:hover {
                      background: #1c2636;
                    }
                  `}
                  onClick={() => {
                    const steamLinkRegex =
                      /https?:\/\/steamcommunity.com\/tradeoffer\/new\/\?partner=(\d+)&token=([-_a-zA-Z0-9]+)$/;
                    if (link && steamLinkRegex.test(link)) {
                      setIsEditMode(false);
                      updateInput({ field: "steamTradeLink", value: link });
                    } else {
                      triggerNotification({
                        type: "error",
                        text: "Link validation error",
                      });
                    }
                  }}
                >
                  <ButtonBlue style={{ color: "rgba(59, 139, 233, 1)", fontSize: 13 }} isDark>
                    {" "}
                    <Image
                      style={{ marginTop: 2 }}
                      src="/images/TheModalBox/check.png"
                      height={18}
                      width={15}
                      alt="image"
                    />
                    {"Save"}
                  </ButtonBlue>
                </Button>
              )
            ) : null}
          </Flex>
        </TheFieldset>
      </BoxItem>
    </>
  );
};
