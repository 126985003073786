import Link from "next/link";
import styled from "styled-components";

export const DesktopView = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  @media (max-width: 1480px) {
    display: none;
  }
`;

export const MobileView = styled.div`
  display: none;
  align-items: center;
  gap: 8px;
  @media (max-width: 1480px) {
    display: flex;
    flex-direction: column;
  }
`;

export const CoinFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Tile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9f4ff06;
  gap: 8px;
  border-radius: 6px;
  padding: 12px;
  width: 100%;
`;

export const Text = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #e9f4ff;
`;

export const ProfileDisplayName = styled(Text)`
  max-width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ProfileOpenButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #e9f4ff0d;
  overflow: hidden;
  &:hover {
    border-color: rgba(210, 234, 255, 0.25);
  }
  cursor: pointer;
  width: 28px;
  height: 28px;
  padding: 8px;
  border-radius: 4px;

  @media (max-width: 1480px) {
    transform: rotate(-90deg);
  }
`;

export const CalendarLink = styled(Link)<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  color: #f2c94c;
  margin-right: 8px;

  /* @media (max-width: 600px) {
    display: none;
  } */
  & svg {
    position: relative;
    top: -3px;
    width: 32px;
    height: 32px;
  }
  & path {
    fill: currentColor;
  }
`;

export const ProfileInfo = styled.div`
  display: flex;
  gap: 4px;
  flex: auto;
`;

export const MobileCoins = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

export const MenuListStyled = styled.div`
  position: relative;
  border: 1px solid #e9f4ff0d;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  background-color: #1D2234;
  margin-top: 16px;

  .title {
    font-size: 14px;
    padding: 16px;
    border-bottom: 1px solid #e9f4ff0d;
  }
  .content {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
  }

  &::before {
    content: url("/images/icons/popup-poligon.svg");
    width: 14px;
    height: 14px;
    position: absolute;
    top: -14px;
    left: calc(50% - 7px);
  }
`;

export const ExchangeBtn = styled.button`
  font-family: "Roboto", sans-serif;
  margin-top: 8px;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #e9f4ffbf;
  background: #e9f4ff1a;
  border: 1px solid #e9f4ff1a;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const MenuBtn = styled.button`
  border: none;
  border-radius: 6px;
  min-width: max-content;
  background: #e9f4ff06;
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #e9f4ffbf;

  svg {
      transition: 0.3s transform ease-in-out;
    }

  &[aria-expanded="true"] .icon svg {
    transform: rotate(180deg);
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    
    gap: 6px;
    padding: 12px;
    pointer-events: initial;
  }

  div {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .icon {
    width: 26px;
    height: 26px;
    background: #e9f4ff0d;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;


    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;

export const BalanceTile = styled.div`
  font-family: "Golos Text";
  padding: 8px 16px;
  display: flex;
  align-items: center;
  background: #e9f4ff06;
  gap: 8px;
  border-radius: 6px;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: #fff;

    small {
      color: #e9f4ff80;

      font-size: 12px;
    }
  }
`;
