import { Spinner } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { formatNumber } from "components/leaderboard/utils/formatNumbers";
import { useAppContext } from "contexts";
import Image from "next/image";
import React from "react";
import { Tile } from "./sharedStyles";
import styled from "styled-components";

type ExchangedPointsTileProps = {
  userId?: string;
};

function ExchangedPointsTile({ userId }: ExchangedPointsTileProps) {
  const { accessFetch, profile } = useAppContext();

  const { data, isFetching: isLoading } = useQuery<{
    pointsExchanged: number;
    gPointsReceive: number;
  }>({
    queryKey: ["total-exchange", userId , profile?.id],
    queryFn: async () => {
      try {
        const isOwn = userId === profile?.id

        const balanceResponse = await accessFetch(`/balance/total-exchange/${isOwn ? "me" : userId}`);

        const data = await balanceResponse?.json();

        if (!balanceResponse?.ok) {
          return Promise.reject(data);
        }

        return data;
      } catch (error) {
        console.error("Error fetching data:", error);
        throw new Error("Error fetching data");
      }
    },
    enabled: !!profile?.id
  });

  return (
    <Tile>
      <div className="flex gap-12 items-center">
        <Image alt="double" src="/images/Balance/double-coin.svg" width={32} height={32} />
        <div className="flex flex-col justify-between gap-12">
          <span>Total Exchanged:</span>
          <StrictGrid>
            <div className="flex items-center justify-center gap-6">
              <Image alt="g-coin" src="/images/coins/w-coin.svg" width={20} height={20} />
              <span className="highlight text-center">
                {formatNumber(data?.pointsExchanged ?? 0)}
              </span>
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <Image alt="exchange" src="/images/Balance/exchange.svg" width={20} height={20} />
            )}
            <div className="flex items-center justify-center gap-6">
              <Image alt="g-coin" src="/images/coins/g-coin.svg" width={20} height={20} />
              <span className="highlight text-center">
                {formatNumber(data?.gPointsReceive ?? 0)}
              </span>
            </div>
          </StrictGrid>
        </div>
      </div>
    </Tile>
  );
}

const StrictGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr minmax(20px, 24px) 1fr;
  gap: 8px;
  align-items: center;
`;

export default ExchangedPointsTile;
