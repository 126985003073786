import React from "react";
import styled, { css } from "styled-components";
import { IStyles, $styles, Button, ButtonBlue } from "components";
import { TheButton, TheFieldset } from "components/TheComponents";
import { useDebouncedCallback } from "use-debounce";
import Image from "next/image";
import { TAppAccessFetch } from "contexts";
import { TheSelect } from "components/TheComponents/TheSelect";

export interface TableHeader {
  setValue: (value: string) => void;
  setSorter: (value: string) => void;
  refetchTable: () => void;
  onOpenRestock: () => void;
  onOpenCreate: () => void;
  isAdminPage: boolean | undefined;
  accessFetch: TAppAccessFetch;
  setSkip: (skip: number) => void;
  skip: number;
}

const BoxContent = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  width: 80%;
  @media (max-width: calc(1180px - 1px)) {
    width: calc(90% - 10px);
  }
  @media (min-width: 1180px) {
    width: 90%;
  }
  @media (min-width: 1280px) {
    width: calc(90% - 5px);
  }
  margin: auto;

  ${$styles}
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;

  ${$styles}
`;

const ButtonTitle = styled.div<IStyles>`
  box-sizing: border-box;
  display: inline-flex;
  color: rgba(210, 234, 255, 0.75);
  font-family: "Roboto";
  font-size: 18px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;
  ${$styles}
`;

export const TableHeader: React.FC<TableHeader> = ({
  setValue,
  setSorter,
  isAdminPage,
  onOpenCreate,
  onOpenRestock,
  skip,
  setSkip,
}): React.ReactElement => {
  const [searchValue, setSearchValue] = React.useState("");
  const debounced = useDebouncedCallback((v) => {
    setValue(v);
    if (skip !== 0) {
      setSkip(0);
    }
  }, 400);

  const options = [
    {
      value: "order:asc",
      disabled: false,
      hidden: false,
      label: "ORDER",
    },
    {
      value: "price",
      disabled: false,
      hidden: false,
      label: "PRICE",
    },
    {
      value: "quantity",
      disabled: false,
      hidden: false,
      label: "QUANTITY",
    },
  ];

  return (
    <>
      <BoxContent id="pointShopContainerFlex">
        <BoxItem
          styles={css`
            flex-grow: 1;
          `}
        >
          <TheFieldset
            styles={css`
              width: 100%;
              border: 1px #1e213a solid;
              height: 50px;
              line-height: 35px;
              border-radius: 8px;
              background-image: url("/images/PachinkoComponent/search-prefix.png");
              background-repeat: no-repeat;
              background-size: 15px 15px;
              background-position: 1rem center;
              text-indent: 2rem;
            `}
          >
            <input
              type="text"
              placeholder="Search"
              value={searchValue}
              onChange={(event) => {
                setSearchValue(event.target.value);
                debounced(event.target.value);
              }}
            />
          </TheFieldset>
        </BoxItem>
        <BoxItem>
          <TheSelect options={options} defaultValue="order:asc" setSorter={setSorter} />
        </BoxItem>
        {isAdminPage && (
          <>
            <BoxItem>
              <TheButton
                preset="dark-blue"
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault();
                  onOpenRestock();
                }}
                styles={css`
                  height: 50px;
                `}
              >
                <Image
                  src="/images/PointShopComponent/refresh.png"
                  width={26}
                  height={24}
                  alt="Logo"
                />
                <ButtonTitle>RESTOCK</ButtonTitle>
              </TheButton>
            </BoxItem>
            <BoxItem>
              <Button
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault();
                  onOpenCreate();
                }}
                styles={css`
                  height: 50px;
                `}
              >
                <ButtonBlue style={{ whiteSpace: "nowrap" }} transparent>
                  Add Item
                </ButtonBlue>
              </Button>
            </BoxItem>
          </>
        )}
      </BoxContent>
    </>
  );
};
