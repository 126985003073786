import Link from "next/link";
import styled, { css } from "styled-components";

import { IStyles } from "components";

export type TTheButtonPreset =
  | "blue"
  | "dark-blue"
  | "save"
  | "violet"
  | "grey"
  | "youtube"
  | "new";

export interface ITheButton {
  children?: React.ReactNode;
  href?: string;
  preset?: TTheButtonPreset;
  width?: number | string | null;
  target?: string;
  height?: number | string | null;
  onClick?: (event: React.MouseEvent) => void;
}

export const TheButton: React.FC<IStyles & ITheButton> = ({
  children,
  href = "#",
  preset,
  target = "_self",
  width = 209,
  height = 50,
  styles,
  onClick,
}): React.ReactElement => {
  const buttonProps = {
    href,
    preset,
    width,
    height,
    styles,
    target,
    onClick,
  };

  return (
    <Button {...buttonProps}>
      {preset === "blue" ? <ButtonBlue>{children}</ButtonBlue> : children}
    </Button>
  );
};

const cssBlue = css`
  background: linear-gradient(265.52deg, #4796f2, #47d3f2);
  box-shadow: 0px 1px 0px #8ac7ff inset;
`;

const cssSave = css`
  background: rgba(59, 139, 233, 0.25);
  border: 1px solid rgba(59, 139, 233, 0.5);
`;

const cssDarkBlue = css`
  background: rgba(36, 39, 70, 0.5);
  border: 1px solid rgba(36, 39, 70, 0.5);
`;

const cssViolet = css`
  background: #683dc4;
  box-shadow: inset 0px 1px 0px rgba(162, 130, 255, 0.54);
`;

const cssGrey = css`
  background-color: #d2eaff;
  box-shadow: 0px 1px 0px #dadada inset;
`;

const cssYoutube = css`
  ${cssGrey}

  &::before {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url("/images/TheButton/iconyoutube16px.svg");
  }
`;

const cssNew = css`
  background: #5ebd3d;
  border-radius: 4px;
`;

const Button = styled(Link)<IStyles & ITheButton>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  ${({ width }) =>
    typeof width === "number"
      ? css`
          width: ${width}px;
        `
      : typeof width === "string"
      ? css`
          width: ${width};
        `
      : null}
  ${({ height }) =>
    typeof height === "number"
      ? css`
          height: ${height}px;
        `
      : typeof height === "string"
      ? css`
          height: ${height};
        `
      : null}

	padding: 10px 32px;
  border-radius: 8px;
  line-height: 150%;
  font-weight: 600;
  cursor: pointer;

  ${({ preset }) =>
    preset === "blue"
      ? cssBlue
      : preset === "dark-blue"
      ? cssDarkBlue
      : preset === "save"
      ? cssSave
      : preset === "violet"
      ? cssViolet
      : preset === "youtube"
      ? cssYoutube
      : preset === "new"
      ? cssNew
      : null}

  ${({ styles }) => styles}
`;

const ButtonBlue = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  background: radial-gradient(50% 50% at 50% 50%, rgba(101, 101, 101, 0.05), rgba(101, 101, 101, 0)),
    #101622;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: inherit;
`;
