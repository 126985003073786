import React from "react";
import {
  $styles,
  $stylesReset,
  Button,
  ButtonBlue,
  DualRingInner,
  DualRingLoader,
  Flex,
  IStyles,
} from "components/common";
import { TheFieldset } from "components/TheComponents";
import styled, { css } from "styled-components";
import { TRANSACTION_STATUS } from "helpers/constants";

interface RejectModalProps {
  onClose: () => void;
  isLoading: boolean;
  actionTransaction: ({
    action,
    reason,
  }: {
    action: string;
    reason: string | undefined;
  }) => Promise<void>;
}

const RejectModal: React.FC<RejectModalProps> = ({
  onClose,
  isLoading,
  actionTransaction,
}): React.ReactElement => {
  const [reason, setReason] = React.useState("");

  const [errors, setErrors] = React.useState<string | undefined>("");

  const validateForm = () => {
    let newErrors;

    if (!reason) {
      newErrors = "Reason is required";
    }

    setErrors(newErrors);

    return typeof newErrors === "undefined"; // Return true if there are no errors
  };

  const rejectPurchase = () => {
    if (!validateForm()) {
      return;
    }
    actionTransaction({ action: TRANSACTION_STATUS.REJECTED, reason });
  };

  return (
    <Flex justify="center" column>
      <Title>Reject this transaction?</Title>
      <Text>
        You are about to reject this user transaction, which will prevent a user of making a
        purchase. <br />
        Are you certain this is what you want to proceed with?
      </Text>
      <BoxItem style={{ marginTop: 20 }}>
        <TheFieldset valid={!errors} title="Reject reason">
          <textarea
            placeholder={errors ? errors : "Reject reason"}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </TheFieldset>
      </BoxItem>
      <Flex style={{ marginTop: 15 }} width="100%" justify="space-between">
        <BoxItem
          styles={css`
            margin-top: 8px;
            margin-right: 12px;
          `}
        >
          <CancelButton onClick={() => onClose()}>Cancel</CancelButton>
        </BoxItem>
        <BoxItem
          styles={css`
            margin-top: 8px;
          `}
        >
          <Button
            disabled={isLoading}
            style={{
              height: 50,
              boxShadow: "none",
              background: "#9c392f",
            }}
            onClick={rejectPurchase}
          >
            <ButtonBlue>
              {isLoading ? (
                <DualRingLoader smallRing>
                  <DualRingInner smallRing />
                </DualRingLoader>
              ) : (
                "Reject"
              )}
            </ButtonBlue>
          </Button>
        </BoxItem>
      </Flex>
    </Flex>
  );
};

export default RejectModal;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;
  ${$styles}
`;

const CancelButton = styled.div`
  box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 50px;
  padding: 10px 32px;
  border-radius: 8px;
  line-height: 150%;
  font-weight: 600;
  cursor: pointer;
  border: 1px #47d3f2 solid;
  background: linear-gradient(265.52deg, #4796f2, #47d3f2);
  background-clip: text;
  -webkit-background-clip: text; /* For Safari/WebKit browsers */
  color: transparent;
`;

const Title = styled.div<IStyles>`
  box-sizing: border-box;
  color: #d2eaff;
  font-family: "Roboto";
  font-size: 22px;
  text-align: center;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;

  ${$styles}
`;
const Text = styled.div<IStyles>`
  ${$stylesReset}
  display: inline-block;
  color: #a2b5c8;
  margin: 5px 0px;
  font-size: 13px;
  text-align: center;
  font-family: "Roboto";
  line-height: 16px;
  text-transform: uppercase;
  ${$styles}
`;
