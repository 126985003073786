import React from "react";
import { $styles, Flex, IStyles } from "components/common";
import { TheButton } from "components/TheComponents";
import styled, { css } from "styled-components";
import { IAppContext, withApp } from "contexts";
import { triggerNotification } from "components/TheComponents/Notification";
import MFAModal from "components/ScreenComponent/MFAModals/MFAModal";
import QRСodeModal from "components/ScreenComponent/MFAModals/QRСodeModal";

interface AdminRestockModalProps {
  onClose: () => void;
}

const AdminRestockModal: React.FC<AdminRestockModalProps & IAppContext> = ({
  accessFetch,
  onClose,
  profile,
  setShowQrModal,
  showQrModal,
}): React.ReactElement => {
  const isMfaEnabled = !!profile?.mfaSecret;
  const [showVerifyModal, setShowVerifyModal] = React.useState<boolean>(false);

  const restockItems = async ({ otp }: { otp: string }) => {
    try {
      const response = await accessFetch("/point-shop/restock-items", {
        method: "POST",
        headers: {
          "x-otp": otp,
        },
      });
      const data = await response?.json();
      if (response && (response.status === 200 || response.status === 201)) {
        triggerNotification({ text: "Items restocked!", type: "success" });
        onClose();
      } else {
        triggerNotification({ text: data?.message, type: "error" });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  //

  return (
    <>
      <Flex justify="center" column>
        <Title>Are you sure you want to restock ALL items to their stock quantity?</Title>
        <Flex style={{ marginTop: 15 }} width="100%" justify="space-between">
          <BoxItem
            styles={css`
              margin-top: 8px;
              margin-right: 12px;
            `}
          >
            <CancelButton onClick={() => onClose()}>Cancel</CancelButton>
          </BoxItem>
          <BoxItem
            styles={css`
              margin-top: 8px;
            `}
          >
            <TheButton
              preset="blue"
              width={"100%"}
              onClick={(e) => {
                e.preventDefault();
                if (isMfaEnabled) {
                  setShowVerifyModal(true);
                } else {
                  setShowQrModal(true);
                }
              }}
            >
              Restock
            </TheButton>
          </BoxItem>
        </Flex>
      </Flex>
      {isMfaEnabled && showVerifyModal && (
        <MFAModal onVerify={restockItems} onClose={() => setShowVerifyModal(false)} />
      )}
      {showQrModal && <QRСodeModal onClose={() => setShowQrModal(false)} />}
    </>
  );
};

export default withApp(AdminRestockModal);

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;
  ${$styles}
`;

const CancelButton = styled.div`
  box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 50px;
  padding: 10px 32px;
  border-radius: 8px;
  line-height: 150%;
  font-weight: 600;
  cursor: pointer;
  background: linear-gradient(265.52deg, #4796f2, #47d3f2);
  box-shadow: 0px 1px 0px #8ac7ff inset;
  color: black;
`;

const Title = styled.div<IStyles>`
  box-sizing: border-box;
  color: #d2eaff;
  font-family: "Roboto";
  font-size: 22px;
  text-align: center;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;

  ${$styles}
`;
