import React from "react";
import { TheModalBox } from "components";
import { $styles, $stylesReset, Flex, IStyles } from "components/common";
import { TheButton, TheFieldset } from "components/TheComponents";
import styled, { css } from "styled-components";
import { IAppContext, withApp } from "contexts";
import { IUserDto } from "interfaces";
import Image from "next/image";
import { triggerNotification } from "components/TheComponents/Notification";
import MFAModal from "components/ScreenComponent/MFAModals/MFAModal";
import QRСodeModal from "components/ScreenComponent/MFAModals/QRСodeModal";

interface ActionUserModalProps {
  onClose: () => void;
  refetch: () => void;
  user: IUserDto;
}

const ActionUserModal: React.FC<ActionUserModalProps & IAppContext> = ({
  onClose,
  user,
  refetch,
  showQrModal,
  profile,
  setShowQrModal,
  accessFetch,
}): React.ReactElement => {
  const isMfaEnabled = !!profile?.mfaSecret;
  const [showVerifyModal, setShowVerifyModal] = React.useState<boolean>(false);
  const isBanned = user?.isBanned;

  const [payload, setPayload] = React.useState<string>("");
  const [errors, setErrors] = React.useState<string | undefined>("");
  const modalBoxProps = {
    hasBlur: true,
    isFixed: true,
    hasBorder: true,
    showClose: false,
    onClose: () => onClose && onClose(),
  };

  const validateForm = (isBanned: boolean) => {
    if (isBanned) {
      return true;
    }

    let newErrors;

    if (!payload) {
      newErrors = "Reason is required";
    }

    setErrors(newErrors);

    return typeof newErrors === "undefined"; // Return true if there are no errors
  };

  const toggleBanUser = async ({ otp }: { otp: string }) => {
    try {
      if (user) {
        const response = await accessFetch(`/user/${user.id}/${isBanned ? "unban" : "ban"}`, {
          method: "PATCH",
          headers: {
            "x-otp": otp,
          },
          ...(!isBanned && { body: JSON.stringify({ banReason: payload }) }),
        });
        const data = await response?.json();

        if (response && (response.status === 200 || response.status === 201)) {
          const actionText = isBanned ? "unbanned" : "banned";
          triggerNotification({ text: `User ${actionText}`, type: "info" });
          refetch();
          onClose();
        } else {
          triggerNotification({ text: data?.message, type: "error" });
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <TheModalBox width={550} height={isBanned ? 250 : 450} {...modalBoxProps}>
      <Flex align="center" justify="center" column>
        {isBanned ? (
          <>
            <Title style={{ marginTop: 10 }}>Unban User?</Title>
            <Text>
              You&apos;re about to unban this user.Are you certain this is what you want to proceed
              with?
            </Text>
          </>
        ) : (
          <>
            <Image src="/images/logos/logo.svg" width={170} height={32} alt="Logo" />
            <Title style={{ marginTop: 10 }}>Ban User?</Title>
            <Text>
              You&apos;re about to block this user, which will prevent any further user&apos;s
              actions. Are you certain this is what you want to proceed with?
            </Text>
            <BoxItem style={{ marginTop: 20 }}>
              <TheFieldset
                styles={css`
                  height: 150px;
                `}
                valid={!errors}
                title="Ban reason"
              >
                <textarea
                  placeholder={errors ? errors : "Ban reason"}
                  value={payload}
                  onChange={(e) => setPayload(e.target.value)}
                />
              </TheFieldset>
            </BoxItem>
          </>
        )}
        <Flex style={{ marginTop: 15 }} width="100%" justify="space-between">
          <BoxItem
            styles={css`
              margin-top: 8px;
              margin-right: 12px;
            `}
          >
            <CancelButton onClick={() => onClose()}>Cancel</CancelButton>
          </BoxItem>
          <BoxItem
            styles={css`
              margin-top: 8px;
            `}
          >
            <TheButton
              preset="blue"
              width={"100%"}
              onClick={(e) => {
                e.preventDefault();
                if (validateForm(isBanned)) {
                  if (isMfaEnabled) {
                    setShowVerifyModal(true);
                  } else {
                    setShowQrModal(true);
                  }
                } else {
                  triggerNotification({ text: "Validation failed", type: "error" });
                }
              }}
            >
              {isBanned ? "Unban" : "Ban"}
            </TheButton>
          </BoxItem>
        </Flex>
      </Flex>
      {isMfaEnabled && showVerifyModal && (
        <MFAModal onVerify={toggleBanUser} onClose={() => setShowVerifyModal(false)} />
      )}
      {showQrModal && <QRСodeModal onClose={() => setShowQrModal(false)} />}
    </TheModalBox>
  );
};

export default withApp(ActionUserModal);

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;
  ${$styles}
`;

const CancelButton = styled.div`
  box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 50px;
  padding: 10px 32px;
  border-radius: 8px;
  line-height: 150%;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid rgba(218, 248, 255, 0.1);
  background: #181f2e;
  // box-shadow: 0px 1px 0px #8ac7ff inset;
  color: rgb(108, 120, 141);
`;

const Title = styled.div<IStyles>`
  box-sizing: border-box;
  color: #d2eaff;
  font-family: "Roboto";
  font-size: 22px;
  text-align: center;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;

  ${$styles}
`;
const Text = styled.div<IStyles>`
  ${$stylesReset}
  display: inline-block;
  color: #a2b5c8;
  margin: 5px 0px;
  font-size: 13px;
  text-align: center;
  font-family: "Roboto";
  line-height: 16px;
  text-transform: uppercase;
  ${$styles}
`;
