import Image from "next/image";
import React from "react";
import styled, { css } from "styled-components";
import { $stylesReset, IStyles } from "components";
import { Button, ButtonBlue } from "components/common";
import { useRouter } from "next/router";
import { JackInTheBox, Slide } from "react-awesome-reveal";

export interface IPointShopComponent {
  id?: string;
}

export const PointShopComponent: React.FC<IPointShopComponent> = ({ id }): JSX.Element => {
  const router = useRouter();
  return (
    <Box id={id}>
      <Screen>
        <Content>
          <Side data-pic>
            <Slide triggerOnce direction="up">
              <Img
                src="/images/PointShopComponent/monitor.png"
                alt="image"
                width={416}
                height={331}
                styles={css`
                  @media (max-width: 600px) {
                    width: 316px;
                    height: 231px;
                  }
                  animation: float ease 10s infinite;
                `}
              />
            </Slide>
            <AbsoluteDiv
              styles={css`
                right: 10px;
                bottom: 80px;
                animation: float ease 5s infinite;
                z-index: 3;
              `}
            >
              <Slide triggerOnce direction="up">
                <Img
                  src="/images/PointShopComponent/front-coin.png"
                  alt="image"
                  width={116}
                  height={113}
                  styles={css`
                    @media (max-width: 600px) {
                      width: 56px;
                      height: 53px;
                    }
                  `}
                />
              </Slide>
            </AbsoluteDiv>
            <AbsoluteDiv
              styles={css`
                left: -20px;
                bottom: 55px;
                animation: float ease 8.3s infinite;
              `}
            >
              <JackInTheBox triggerOnce>
                <Img
                  src="/images/PointShopComponent/coins.png"
                  alt="image"
                  width={213}
                  height={151}
                  styles={css`
                    @media (max-width: 600px) {
                      width: 113px;
                      height: 80px;
                    }
                  `}
                />
              </JackInTheBox>
            </AbsoluteDiv>
            <AbsoluteDiv
              styles={css`
                left: -60px;
                top: 105px;
                animation: float ease 7s infinite;
              `}
            >
              <Slide triggerOnce direction="left">
                <Img
                  src="/images/PointShopComponent/left-coin.png"
                  alt="image"
                  width={45}
                  height={45}
                />
              </Slide>
            </AbsoluteDiv>
            <AbsoluteDiv
              styles={css`
                left: 200px;
                top: -40px;
                animation: float ease 4.3s infinite;
                @media (max-width: 600px) {
                  left: -60px;
                }
              `}
            >
              <Slide triggerOnce direction="down">
                <Img
                  src="/images/PointShopComponent/back-coin.png"
                  alt="image"
                  width={134}
                  height={134}
                  styles={css`
                    @media (max-width: 600px) {
                      width: 80px;
                      height: 80px;
                    }
                  `}
                />
              </Slide>
            </AbsoluteDiv>
            <AbsoluteDiv
              styles={css`
                right: 20px;
                top: -10px;
                animation: float ease 4.3s infinite;
              `}
            >
              <JackInTheBox triggerOnce>
                <Img
                  src="/images/PointShopComponent/right-coin.png"
                  alt="image"
                  width={31}
                  height={31}
                />
              </JackInTheBox>
            </AbsoluteDiv>
          </Side>
          <Side data-text>
            <TheTitle>
              <span data-gold>personalized rewards</span>
              <br />
              <span> in our </span>
              <span data-blue>Points Shop</span>
              <span>!</span>
            </TheTitle>
            <TheMessage>
              Earn points by watching WatchGamesTV Live Streams and use them to buy personalized
              rewards in Points Shop. Free Crypto, Casino Bonuses and real items! Try now!
            </TheMessage>
            <Button
              onClick={(event: React.MouseEvent) => {
                event.preventDefault();
                router.push("/points-shop");
              }}
              style={{ height: 50, cursor: "pointer", width: 209 }}
            >
              <ButtonBlue style={{ whiteSpace: "nowrap" }} transparent>
                OPEN SHOP
              </ButtonBlue>
            </Button>
          </Side>
        </Content>
      </Screen>
    </Box>
  );
};

const AbsoluteDiv = styled.div<IStyles>`
  position: absolute;
  z-index: 1;
  ${({ styles }) => styles}
`;

const Box = styled.div`
  ${$stylesReset}
  background-color: #0b0e16;
`;

const Screen = styled.div`
  ${$stylesReset}
  position: relative;
  max-width: 1920px;
  padding-top: 80px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  overflow: hidden;
`;

const Content = styled.div`
  ${$stylesReset}
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  margin: 24px;
  @media (min-width: 1280px) {
    & [data-pic] {
      order: 1;
    }
    & [data-text] {
      order: 2;
    }
    flex-direction: row;
    gap: 48px;
    max-width: 1152px; // 1068px;
    max-height: 630px;
    padding-top: calc(82px - 24px);
    padding-bottom: calc(90px - 24px);
  }
`;

const Side = styled.div`
  --width: 556px;
  --height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &[data-pic] {
    height: var(--height);
  }
  &[data-text] {
    /* text-align: center; */
  }
  @media (max-width: 600px) {
    &[data-pic] {
      height: calc(var(--height) * 0.6);
    }
    &[data-text] {
      width: 100%;
    }
  }
  @media (max-width: calc( 1068px - 1 )) {
    max-width: var(--width);
    width: 100%;
  }
  @media (min-width: 1068px) {
    width: var(--width);
    &[data-text] {
      align-items: flex-start;
      height: var(--height);
      text-align: left;
    }
  }
`;


const Img = styled(Image)<IStyles>`
  ${({ styles }) => styles}
`;

const TheTitle = styled.div`
  /* width: 500px; // 464px; */
  font-size: 24px;
  @media (min-width: 1068px) {
    font-size: 40px;
  }
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: 130%;
  text-transform: uppercase;

  // Gloden letters
  & span[data-gold] {
    color: #f2c94c;
  }

  // Blue letters
  & span[data-blue] {
    color: #3b8be9;
  }
`;

const TheMessage = styled.div`
  /* width: 464px; */
  margin: 24px 0 48px;
  letter-spacing: 0.02em;
  line-height: 150%;
  font-weight: 500;
  color: rgba(210, 234, 255, 0.75);
`;
