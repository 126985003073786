import React, { FC, ReactNode } from "react";
import styled from "styled-components";

type GridProps = {
  children: ReactNode;
};

const AdaptiveGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  min-width: 100%;
  grid-template-columns: repeat(5, 1fr);
  @media (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Grid: FC<GridProps> = ({ children }) => {
  return <AdaptiveGrid>{children}</AdaptiveGrid>;
};

export default Grid;
