import React, { Dispatch, SetStateAction } from "react";
import * as luxon from "luxon";
import { Button, ButtonBlue, Flex } from "components/common";
import Image from "next/image";
import { Card, SubHeader, Title, ExtendedCard } from "../ProfileLayout";
// import { formatNumber } from "components/pachinko/Prizes";
import { IUserDto } from "interfaces";
import { AddressInput } from "./AddressInput";
import styled, { css } from "styled-components";
import { TAppAccessFetch } from "contexts";
import { TheButton, TheIcon } from "components/TheComponents";
import UpdateBalanceModal from "./UpdateBalanceModal";
import { DEFAULT_AVATAR } from "helpers/constants";
import { BoxItem } from "../ProfileLayout";
import ActionUserModal from "./ActionUserModal";
import { SteamLinkInput } from "./SteamLinkInput";
// import { ThePointsBalance } from "components/TheComponents/ThePointsBalance";

import ExchangedPointsTile from "./ExchangedPointsTile";
import { Balances, MainTilesGrid, StrictGrid, Tile } from "./sharedStyles";
import { formatNumber as formatNumberNew } from "components/leaderboard/utils/formatNumbers";
// import { Tooltip } from "@chakra-ui/react";
import Link from "next/link";

export interface MainProps {
  user: IUserDto | null;
  isMobile: boolean;
  isAdminCanEdit: boolean;
  showDiscordId: boolean;
  isOwnProfile: boolean;
  refetchUser: () => void;
  refetchUsers?: () => void;
  // showQrModal: boolean;
  // setShowQrModal: Dispatch<SetStateAction<boolean>>;
  updateInput: ({ field, value }: { field: string; value: string | number }) => Promise<void>;
  accessFetch: TAppAccessFetch;
}

const arrayOfInputs = [
  {
    image: "/images/TheModalBox/btc.png",
    placeholder: "Enter BTC address",
    field: "btcAddress",
    currency: "btc",
  },
  {
    image: "/images/TheModalBox/eth.png",
    placeholder: "Enter ETH address",
    field: "ethAddress",
    currency: "eth",
  },
  {
    image: "/images/TheModalBox/usdt.png",
    placeholder: "Enter USDT(ERC20) address",
    field: "usdtErc20Address",
    currency: "eth",
  },
  {
    image: "/images/TheModalBox/ltc.png",
    placeholder: "Enter LTC address",
    field: "ltcAddress",
    currency: "ltc",
  },
];

const ExtendedFlex = styled(Flex)`
  @media (max-width: 480px) {
    /* flex-direction: column; */
    .mobile-hidden {
      display: none;
    }
  }
`;

const TooltipInfo = styled.div`
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .red {
    color: #b33e31;
  }

  .green {
    color: green;
  }
`;

const Alert = styled.div`
  font-family: "Roboto", sans-serif;
  color: #b33e31;
  background: #181f2e;
  padding: 8px 16px;
  margin-bottom: 16px;
  border-radius: 8px;

  a {
    text-decoration: underline;
  }
`;

const Info = styled.div`
  border: 1px solid #bc1c2e;
  padding: 10px 20px 10px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 1rem;
  background: #c842301a;

  p {
    line-height: 150%;
    font-size: 14px;
    color: #d2eaff;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    margin: 0;
  }
`;

export const Main: React.FC<MainProps> = ({
  user,
  isMobile,
  accessFetch,
  updateInput,
  refetchUser,
  refetchUsers,
  isAdminCanEdit,
  isOwnProfile,
  showDiscordId,
}): React.ReactElement => {
  const [showEditBalanceModal, setShowEditBalanceModal] = React.useState(false);
  const [showActionModal, setShowActionModal] = React.useState(false);

  const isBanned = user?.isBanned;

  return (
    <>
      {!isMobile ? (
        <Flex width="100%" justify="space-between" align="center">
          <Title
            style={{
              fontSize: 16,
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            {user?.displayName}
            <span style={{ color: "#444e5c" }}> (ID{user?.id})</span>
          </Title>
          {isBanned ? (
            <Card
              style={{
                padding: "7px 0px",
                width: 150,
                marginRight: 5,
                height: 30,
                border: "none",
              }}
            >
              <Flex align="center">
                <SubHeader style={{ fontSize: 12, color: "#b33e31" }}>Banned sdsd</SubHeader>
              </Flex>
            </Card>
          ) : null}

          {/* {user?.userMultiConnections.some((connection) => !connection.isIgnored) ? (
            <Tooltip
              placement="left"
              label={
                <TooltipInfo>
                  {user?.userMultiConnections?.map((connection) => {
                    return (
                      <div
                        key={`${connection.type}-${connection.value}`}
                        style={{ display: "flex", justifyContent: "space-between", gap: 8 }}
                      >
                        <span>{connection.type}</span>
                        <span className={connection.isIgnored ? "green" : "red"}>
                          {connection.isIgnored ? "Ignored" : "Not-ignored"}
                        </span>
                      </div>
                    );
                  })}
                </TooltipInfo>
              }
              fontSize="sm"
            >
              <Image
                alt="g-coin"
                src="/images/icons/circle_question_icon.svg"
                width={24}
                height={24}
              />
            </Tooltip>
          ) : null} */}
        </Flex>
      ) : null}
      <ExtendedFlex margin="0 0 10px" justify="space-between" width="100%" gap={16}>
        <Flex style={{ flexGrow: 1 }}>
          <Image
            style={{ borderRadius: "50%" }}
            src={user?.profileImageUrl ? user?.profileImageUrl : DEFAULT_AVATAR}
            height={80}
            width={80}
            alt="image"
          />
          {isMobile ? (
            <Title
              style={{
                fontSize: 16,
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              {user?.displayName}
              <span style={{ color: "#444e5c" }}> (ID{user?.id})</span>
            </Title>
          ) : null}
          <Flex width="100%" style={{ marginLeft: 15 }} column>
            <SubHeader
              style={{
                fontSize: isMobile ? 16 : 12,
                paddingLeft: 0,
                color: "#6C788D",
              }}
            >
              Joined: {luxon.DateTime.fromISO(user?.createdAt || "").toFormat("dd.MM.yyyy")}
            </SubHeader>
            <SubHeader
              style={{
                fontSize: isMobile ? 16 : 12,
                paddingLeft: 0,
                color: "#6C788D",
              }}
            >
              Email: {user?.email}
            </SubHeader>
            <SubHeader
              style={{
                fontSize: isMobile ? 16 : 12,
                paddingLeft: 0,
                color: "#6C788D",
              }}
            >
              Discord: {user?.discordUsername || "N/A"}
            </SubHeader>
            {showDiscordId ? (
              <SubHeader
                style={{
                  fontSize: isMobile ? 16 : 12,
                  paddingLeft: 0,
                  color: "#6C788D",
                }}
              >
                DiscordId: {user?.discordId || "N/A"}
              </SubHeader>
            ) : null}
          </Flex>
          {isMobile ? (
            <Flex width={"100%"} padding="5px" column>
              {isBanned ? (
                <Card
                  style={{
                    padding: "7px 0px",
                    marginBottom: 15,
                    height: 30,
                    border: "none",
                  }}
                >
                  <Flex align="center">
                    <SubHeader style={{ fontSize: 12, color: "#b33e31" }}>Banned</SubHeader>
                  </Flex>
                </Card>
              ) : null}
            </Flex>
          ) : null}
        </Flex>

        <Balances>
          <Tile>
            <StrictGrid>
              <span className="flex gap-6 flex-g-1 text-right mobile-hidden">
                {isAdminCanEdit && !isMobile ? (
                  <Button
                    styles={css`
                      cursor: pointer;
                      padding: 0px;
                    `}
                    preset="blue"
                    isDark
                  >
                    <ButtonBlue
                      onClick={() => {
                        setShowEditBalanceModal(true);
                      }}
                      isDark
                    >
                      <TheIcon icon="action:edit" size={16} />
                    </ButtonBlue>
                  </Button>
                ) : null}
                W-Points
              </span>
              <Image alt="g-coin" src="/images/coins/w-coin.svg" width={20} height={20} />
              <span className="highlight flex-g-1">
                {formatNumberNew(user?.pointsBalance ?? 0)}
              </span>
            </StrictGrid>
          </Tile>
          <Tile>
            <StrictGrid>
              <span className="flex-g-1 text-right mobile-hidden">G-Points</span>
              <Image alt="g-coin" src="/images/coins/g-coin.svg" width={20} height={20} />
              <span className="highlight flex-g-1">
                {formatNumberNew(user?.gPointsBalance ?? 0)}
              </span>
            </StrictGrid>
          </Tile>
        </Balances>
      </ExtendedFlex>

      {user?.userMultiConnections?.some((connection) => !connection.isIgnored) ? (
        <Alert>
          <Link href={`/admin/users-multi?search=${user.id}`}>
            Multi account alert: {user?.userMultiConnections.map((c) => c.type).join(", ")}
          </Link>
        </Alert>
      ) : null}

      {isBanned && isAdminCanEdit ? (
        <>
          <SubHeader
            style={{
              fontSize: 15,
              color: "#b33e31",
              textAlign: "left",
              marginBottom: 20,
            }}
          >
            Ban Reason:
          </SubHeader>
          <Card style={{ padding: "7px 0px", width: "100%" }}>
            <Flex padding="10px" width="100%" align="flex-start" justify="flex-start">
              <SubHeader
                style={{
                  fontSize: 12,
                  color: "rgba(210, 234, 255, 0.75)",
                  textAlign: "left",
                }}
              >
                {user?.banReason}
              </SubHeader>
            </Flex>
          </Card>
        </>
      ) : (
        <></>
      )}
      <MainTilesGrid>
        <Tile>
          <div className="flex gap-12 items-center">
            <Image
              src="/images/PointShopComponent/tickets.png"
              height={32}
              width={32}
              alt="image"
            />
            <div className="flex flex-col justify-between gap-12">
              <span>Raffles won:</span>
              <span className="highlight text-center">
                {formatNumberNew(user?.rafflesWon ?? 0)}
              </span>
            </div>
          </div>
        </Tile>
        <Tile>
          <div className="flex gap-12 items-center">
            <Image src="/images/PointShopComponent/coin.png" height={32} width={32} alt="image" />
            <div className="flex flex-col justify-between gap-12">
              <span>Points spent:</span>
              <span className="highlight text-center">
                {formatNumberNew(user?.pointsSpent ?? 0)}
              </span>
            </div>
          </div>
        </Tile>

        <ExchangedPointsTile userId={user?.id} />
      </MainTilesGrid>

      <Title>MY STEAM TRADELINK</Title>
      <SteamLinkInput
        isEditable={isOwnProfile || isAdminCanEdit}
        updateInput={updateInput}
        steamTradeLink={user?.steamTradeLink || ""}
      />
      <Title>MY CRYPTO</Title>
      <Info>
        <Image width={48} height={48} src={"/images/icons/alert.svg"} alt="" />
        <p>
          Please ensure that you use your own wallet addresses and not the deposit addresses of
          casinos, as these can change periodically. If a prize is paid out to an incorrect address,
          we will not be able to refund it.
        </p>
      </Info>
      {arrayOfInputs?.map((input) => {
        const addressKey = input.field as keyof IUserDto;
        return (
          <AddressInput
            isEditable={isOwnProfile || isAdminCanEdit}
            currency={input.currency}
            accessFetch={accessFetch}
            userId={user?.id}
            addressValue={user?.[addressKey]}
            refetchUser={refetchUser}
            field={input.field}
            key={input.field}
            inputBg={input.image}
            placeholder={input.placeholder}
          />
        );
      })}
      {/* <Title>MY CASINO STATISTICS</Title>
      <Flex switchToColumn width="100%">
        <ExtendedCard
          isCasino
          style={{
            width: isMobile ? "100%" : "50%",
            marginRight: 20,
            ...(isMobile ? { margin: "auto" } : {}),
          }}
        >
          <Flex width="100%" margin="auto" column>
            <Flex
              cursor="pointer"
              style={{ borderBottom: "2px #1d2431 solid", paddingBottom: 10 }}
              align="center"
              width="100%"
              justify="center"
            >
              <Image
                alt="image"
                width={134}
                height={18}
                src="/images/leaderboard/LeaderboardSwitcher/Hyperdrop-134x18.svg"
                style={{ marginRight: 5 }}
              />
              <Image
                alt="image"
                width={11}
                height={11}
                src="/images/TheModalBox/external-link.png"
              />
            </Flex>
            <Flex width="100%" justify="center" align="center" column>
              <Flex color="white" margin="10px 0px">
                <span style={{ color: "rgb(108, 120, 141)", marginRight: 10 }}>Wagered</span>{" "}
                $21,300.22
              </Flex>
              <Flex justify="center" align="center" margin="10px 0px">
                <span style={{ color: "rgb(108, 120, 141)", marginRight: 10 }}>Go to profile</span>{" "}
                <Image alt="image" width={11} height={14} src="/images/TheModalBox/polygon.png" />
              </Flex>
            </Flex>
          </Flex>
        </ExtendedCard>
        <ExtendedCard
          style={{
            width: isMobile ? "100%" : "50%",
            marginRight: isMobile ? 0 : 20,
            ...(isMobile ? { margin: "20px auto" } : {}),
          }}
        >
          <Flex width="100%" margin="auto" column>
            <Flex
              cursor="pointer"
              style={{ borderBottom: "2px #1d2431 solid", paddingBottom: 10 }}
              align="center"
              width="100%"
              justify="center"
            >
              <Image
                alt="image"
                width={134}
                height={18}
                src="/images/leaderboard/LeaderboardSwitcher/Hyperdrop-134x18.svg"
                style={{ marginRight: 5 }}
              />
              <Image
                alt="image"
                width={11}
                height={11}
                src="/images/TheModalBox/external-link.png"
              />
            </Flex>
            <Flex width="100%" justify="center" align="center" column>
              <Flex color="white" margin="10px 0px">
                <span style={{ color: "rgb(108, 120, 141)", marginRight: 10 }}>Wagered</span>{" "}
                $21,300.22
              </Flex>
              <Flex justify="center" align="center" margin="10px 0px">
                <span style={{ color: "rgb(108, 120, 141)", marginRight: 10 }}>Go to profile</span>{" "}
                <Image alt="image" width={11} height={14} src="/images/TheModalBox/polygon.png" />
              </Flex>
            </Flex>
          </Flex>
        </ExtendedCard>
      </Flex> */}
      {isAdminCanEdit ? (
        <>
          <Title style={{ marginTop: 10 }}>MANAGE USER</Title>
          <Flex width="100%" align="center">
            <BoxItem
              styles={css`
                margin-bottom: 10px;
              `}
            >
              <TheButton
                styles={css`
                  background-color: ${isBanned ? "#448237" : "#9c392f"};
                  color: black;
                `}
                width={"100%"}
                onClick={(e) => {
                  e.preventDefault();
                  setShowActionModal(true);
                }}
              >
                {isBanned ? "Unban" : "Ban"}
              </TheButton>
            </BoxItem>
          </Flex>
        </>
      ) : null}
      {showEditBalanceModal ? (
        <UpdateBalanceModal
          refetch={() => {
            if (refetchUsers) {
              refetchUsers();
            }
            if (refetchUser) {
              refetchUser();
            }
          }}
          userId={user?.id as string}
          userBalance={user?.pointsBalance as number}
          onClose={() => setShowEditBalanceModal(false)}
        />
      ) : null}
      {showActionModal ? (
        <ActionUserModal
          refetch={() => {
            if (refetchUsers) {
              refetchUsers();
            }
            if (refetchUser) {
              refetchUser();
            }
          }}
          user={user}
          onClose={() => {
            setShowActionModal(false);
          }}
        />
      ) : null}
    </>
  );
};
