import { LootboxTransaction } from "components/admin/lootbox-transaction/AdminLootboxTransactionTable";
import {
  Status,
  Title,
} from "components/admin/lootbox-transaction/AdminLootboxTransactionTable/styles";
import {
  DualRingInner,
  DualRingLoader,
  Flex,
  ITheTableCell,
  Pages,
  TheTable,
} from "components/common";
import { TopTenTableOptions } from "components/pachinko/Table/TopTenTable";
import { IAppContext, withApp } from "contexts";
import * as luxon from "luxon";
import React from "react";
import { useEffect } from "react";
import styled from "styled-components";

export type CaseItemsTabProps = {} & IAppContext;

const CaseItemsTab: React.FC<CaseItemsTabProps> = ({ accessFetch }): React.ReactElement => {
  const [skip, setSkip] = React.useState<number>(0);
  const [take, setTake] = React.useState<number>(5);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [total, setTotal] = React.useState<number>(0);
  const [items, setItems] = React.useState<any[]>([]);

  const setup = React.useMemo(
    () => [
      {
        $key: "id",
        render: (props: ITheTableCell<LootboxTransaction, TopTenTableOptions>) => {
          return props.cell;
        },
        title: <Title textAlign="left">Id</Title>,
      },
      {
        $key: "item",
        render: (props: ITheTableCell<LootboxTransaction, TopTenTableOptions>) => {
          return (
            <Item>
              <ItemImg src={props.cell.imageUrl} height={32} />
              {props.cell.title}
            </Item>
          );
        },
        title: <Title textAlign="left">Item</Title>,
      },
      {
        $key: "price",
        render: (props: ITheTableCell<LootboxTransaction, TopTenTableOptions>) => {
          return props.cell;
        },
        title: <Title textAlign="left">Price $</Title>,
      },
      {
        $key: "status",
        render: (props: ITheTableCell<LootboxTransaction, TopTenTableOptions>) => {
          const status = props.cell;
          return <Status status={status}>{status}</Status>;
        },
        title: <Title textAlign="left">Status</Title>,
      },
      {
        $key: "updatedAt",
        render: (props: ITheTableCell<LootboxTransaction, TopTenTableOptions>) => {
          return luxon.DateTime.fromISO(props.cell).toFormat("dd.MM.yyyy");
        },
        title: <Title textAlign="left">Updated</Title>,
      },
    ],
    []
  );

  const fetchData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await accessFetch(`loot-box/my-items?take=${take}&skip=${skip}`);
      const data = await res?.json();

      setItems(data.data);
      setTotal(data.total);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  }, [accessFetch, take, skip]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onPage = (index: number) => {
    setSkip(index * take);
    setTake(take);
  };
  return (
    <Flex align="center" column width="100%">
      {isLoading ? (
        <Flex align="center" justify="center" padding="60px 0px">
          <DualRingLoader>
            <DualRingInner />
          </DualRingLoader>
        </Flex>
      ) : (
        <TableWrapper>
          <TheTable<LootboxTransaction, TopTenTableOptions>
            $keys={["id", "item", "price", "status", "updatedAt"]}
            rows={
              isLoading
                ? new Array(5).fill({})
                : items.map((item) => ({
                    ...item,
                    item: { title: item.lootBoxItem.title, imageUrl: item.lootBoxItem.imageUrl },
                    price: item.lootBoxItem.priceDollars,
                  })) ?? []
            }
            $setup={setup}
          />
        </TableWrapper>
      )}
      <Pages
        skip={skip}
        take={take}
        total={total}
        showInfo={false}
        onClick={(index) => {
          onPage(index);
        }}
      />
    </Flex>
  );
};

export default withApp(CaseItemsTab);

const TableWrapper = styled.div`
  width: 100%;
  & > div {
    width: 100%;
  }
`;

export const Item = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ItemImg = styled.img``;
